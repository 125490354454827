import { getUserEmail } from './xw-api'

const endUsersEmail = {}
export const getEndUserEmail = (customerId) => {
  if (endUsersEmail[customerId]) {
    return endUsersEmail[customerId]
  } else {
    endUsersEmail[customerId] = getUserEmail(customerId)
    return endUsersEmail[customerId]
  }
}
