import React from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import { getApi } from '../../api'
import ActionButton from '../components/action-button'
import VatesLogo from '../../imgs/vates-logo.png'
import Loader from '../components/loader'

const withState = provideState({
  initialState: () => ({
    id: '',
    password: '',
    errorMessage: '',
    authLoading: false,
    modal: false,
  }),
  effects: {
    initialize: (effects) => (state, props) => {
      // if already logged, redirect to billingInfo
      if (state.logged) {
        window.location.replace('/#/billingInfo')
      }
    },
    handleId: (_, { target: { value } }) => ({
      id: value,
      errorMessage: '',
    }),
    handlePassword: (_, { target: { value } }) => ({
      password: value,
      errorMessage: '',
    }),
    async logIn(effects, event) {
      if (event) {
        event.preventDefault()
      }
      this.state.authLoading = true
      try {
        const account = await getApi().authenticate(
          this.state.id,
          this.state.password
        )
        await this.props.onSubmit(account.token)
        if (
          this.props.location &&
          this.props.location.from &&
          this.props.location.from.pathname
        ) {
          window.location.replace(`/#${this.props.location.from.pathname}`)
        } else {
          window.location.replace('/#/billinginfo')
        }
      } catch (err) {
        await effects.handleError(err)
      }
      this.state.authLoading = false
    },
    toggleModal(effects) {
      this.state.modal = !this.state.modal
    },
    async sendMail(effects) {
      try {
        await getApi().passwordLost(this.state.id)
        effects.notify(
          'success',
          'If this email is registered, you will receive a link to reset your password'
        )
        this.state.modal = false
      } catch (error) {
        await effects.handleError(error)
      }
    },
  },
  computed: {
    loading: (state, props) => state.authLoading || props.loading,
  },
})

const AuthentificationForm = ({ effects, state }) =>
  state.loading ? (
    <Loader />
  ) : (
    <Row style={{ marginTop: '50px' }} className="justify-content-center">
      <Col md="6" lg="4">
        <Card body>
          <CardTitle>
            <div className="text-center">
              <img src={VatesLogo} alt="Vates" width="100" />
              <div className="card-title">Welcome!</div>
            </div>
          </CardTitle>
          <CardBody>
            <Form id="authentication-form">
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Email"
                  value={state.id}
                  onChange={effects.handleId}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="password"
                  placeholder="Password"
                  value={state.password}
                  onChange={effects.handlePassword}
                  required
                />
              </FormGroup>
              <ActionButton
                action={effects.logIn}
                block={true}
                color="success"
                disabled={state.password === '' || state.id === ''}
                form="authentication-form"
                text="LOG IN"
              />
            </Form>
          </CardBody>
          <div className="card-footer-link">
            <a href="/#/signup" className="link-primary">
              No account? Sign Up!
            </a>
            <div>
              <span onClick={effects.toggleModal} className="link-muted">
                Lost activation email or forgot your password?
              </span>
            </div>
          </div>
        </Card>
        <Modal isOpen={state.modal} toggle={effects.toggleModal}>
          <ModalHeader toggle={effects.toggleModal}>
            Account recovery
          </ModalHeader>
          <ModalBody>
            Please give us your email to reset the password of your account.
            <Form id="lost-form">
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Email *"
                  value={state.id}
                  onChange={effects.handleId}
                  required
                />
              </FormGroup>
              <ActionButton
                action={effects.sendMail}
                block={true}
                color="success"
                disabled={state.id === ''}
                form="lost-form"
                text="Send"
              />
            </Form>
          </ModalBody>
        </Modal>
      </Col>
    </Row>
  )

export default withState(injectState(AuthentificationForm))
