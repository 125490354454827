import React, { Fragment } from 'react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements'
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import ActionButton from '../../components/action-button'
import { getApi } from '../../../api'
import * as Utils from '../../../utils'

import './style.css'

const withState = provideState({
  initialState: () => ({
    cardNumberChangeObject: undefined,
    expirationDateChangeObject: undefined,
    cvcChangeObject: undefined,
    cardHolderName: ''
  }),
  effects: {
    handleCardNumberChange: (_, changeObject) => ({
      cardNumberChangeObject: changeObject
    }),
    handleExpirationDate: (_, changeObject) => ({
      expirationDateChangeObject: changeObject
    }),
    handleCVC: (_, changeObject) => ({
      cvcChangeObject: changeObject
    }),
    handleCardHolderNameChange: (_, e) => ({
      cardHolderName: e.target.value.trimStart()
    }),
    async createToken(effects) {
      try {
        const answer = await this.props.stripe.createToken({
          name: this.state.cardHolderName.trim()
        })
        if (answer.error) {
          await effects.notify('error', answer.error.message)
          return
        }
        const { token } = answer
        if (this.state.validCardNumberInfo) {
          if (this.props.mode === 'ADD_CARD') {
            await getApi().addStripeCard(this.props.customerId, token.id)
            await effects.notify('success', 'Card added successfully')
          } else {
            await getApi().replaceStripeCard(this.props.customerId, token.id)
            await effects.notify('success', 'Card changed successfuly')
          }
          await this.props.updateData()
          this.props.onFinish()
        }
      } catch (error) {
        effects.handleError(error)
      }
    }
  },
  computed: {
    validCardNumberInfo: ({ validCardNumber, validExpirationDate, validCVC }) =>
      validCardNumber && validExpirationDate && validCVC,
    validCardNumber: ({ cardNumberChangeObject }) =>
      Utils.get(() => cardNumberChangeObject.complete),
    validExpirationDate: ({ expirationDateChangeObject }) =>
      Utils.get(() => expirationDateChangeObject.complete),
    validCVC: ({ cvcChangeObject }) =>
      Utils.get(() => cvcChangeObject.complete),
    cardNumberErrorMessage: ({ cardNumberChangeObject }) =>
      Utils.get(() => cardNumberChangeObject.error.message),
    expirationDateErrorMessage: ({ expirationDateChangeObject }) =>
      Utils.get(() => expirationDateChangeObject.error.message),
    cvcErrorMessage: ({ cvcChangeObject }) =>
      Utils.get(() => cvcChangeObject.error.message)
  }
})

const CheckoutForm = ({ state, effects, mode }) => (
  <Fragment>
    <Form id="credit-card-form" className="checkout">
      <Row>
        <Col md={12} lg={5}>
          <FormGroup>
            <Label for="card-holder-element">Cardholder name</Label>
            <Input
              id="card-holder-element"
              className="cardName"
              maxLength="255"
              minLength="2"
              type="text"
              required
              value={state.cardHolderName}
              onChange={effects.handleCardHolderNameChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={5}>
          <FormGroup>
            <Label for="card-number-element">Card number</Label>
            <CardNumberElement
              id="card-number-element"
              className="cardNumber"
              onChange={effects.handleCardNumberChange}
            />
          </FormGroup>
        </Col>
        <Col md={12} lg={3}>
          <FormGroup>
            <Label for="card-expiry-date">Expiration date</Label>
            <CardExpiryElement
              id="card-expiry-date"
              className="expirationDate"
              onChange={effects.handleExpirationDate}
            />
          </FormGroup>
        </Col>
        <Col md={12} lg={3}>
          <FormGroup>
            <Label for="card-cvc-element">CVC</Label>
            <CardCVCElement
              id="card-cvc-element"
              className="cvc"
              onChange={effects.handleCVC}
            />
          </FormGroup>
        </Col>
      </Row>
      <ActionButton
        form="credit-card-form"
        action={effects.createToken}
        classNames="float-right"
        color="success"
        text={mode === 'ADD_CARD' ? 'Save my card' : 'Update my card'}
      />
      <p style={{ color: 'red' }}>
        <span className="error-message">
          {!state.validCardNumber && state.cardNumberErrorMessage}{' '}
        </span>
        <span className="error-message">
          {!state.validExpirationDate && state.expirationDateErrorMessage}{' '}
        </span>
        <span className="error-message">
          {!state.validCVC && state.cvcErrorMessage}{' '}
        </span>
      </p>
    </Form>
  </Fragment>
)

export default injectStripe(withState(injectState(CheckoutForm)))
