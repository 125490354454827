import React from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import { FaCaretRight, FaCaretDown } from 'react-icons/fa'
import { injectState, provideState } from 'reaclette'
import { Order } from '@vates/www-xo-utils'

import { getApi } from '../../../api'
import VatesBankInfo from '../vates-bank-info'

const initCollapsesState = (order) => {
  if (
    (order.signedQuotes.length > 0 ||
      order.proformas.length > 0 ||
      order.purchaseOrders.length > 0) &&
    order.transferProofs.length === 0
  ) {
    return {
      first: false,
      second: true,
    }
  }
  return {
    first: true,
    second: false,
  }
}

const withState = provideState({
  initialState: (props) => ({
    collapses: initCollapsesState(props.order),
    files: {
      purchaseOrder: null,
      signedQuote: null,
      transferProof: null,
    },
    modal: false,
    uploading: false,
  }),
  effects: {
    handleChangeFile(_, e) {
      this.state.files = {
        ...this.state.files,
        [e.target.name]: e.target.files[0],
      }
    },
    onFormSignedQuoteOrderSubmit(effects, e, orderId) {
      e.preventDefault()
      this.state.uploading = true
      getApi(this.state.role)
        .uploadSignedQuoteToOrder(
          this.state.account.token,
          orderId,
          this.state.files.signedQuote
        )
        .then(() => {
          effects.notify('success', 'File successfully uploaded')
          document.getElementById('signedQuote').value = ''
          this.state.files.signedQuote = null
          effects.loadOrders()
        })
        .catch((error) => {
          effects.notify('error', 'Error while uploading')
        })
        .finally(() => {
          this.state.uploading = false
        })
    },
    onFormPurchaseOrderSubmit(effects, e, orderId) {
      e.preventDefault()
      this.state.uploading = true
      getApi(this.state.role)
        .uploadPurchaseOrderToOrder(
          this.state.account.token,
          orderId,
          this.state.files.purchaseOrder
        )
        .then(() => {
          effects.notify('success', 'File successfully uploaded')
          document.getElementById('purchaseOrder').value = ''
          this.state.files.purchaseOrder = null
          effects.loadOrders()
        })
        .catch((error) => {
          effects.notify('error', 'Error while uploading')
        })
        .finally(() => {
          this.state.uploading = false
        })
    },
    onFormTransferProofSubmit(effects, e, orderId) {
      e.preventDefault()
      this.state.uploading = true
      getApi(this.state.role)
        .uploadTransferProofToOrder(
          this.state.account.token,
          orderId,
          this.state.files.transferProof
        )
        .then(() => {
          effects.notify('success', 'File successfully uploaded')
          document.getElementById('transferProof').value = ''
          this.state.files.transferProof = null
          effects.loadOrders()
        })
        .catch((error) => {
          effects.notify('error', 'Error while uploading')
        })
        .finally(() => {
          this.state.uploading = false
        })
    },
    generateQuoteFromOrder(effects, orderId) {
      this.state.uploading = true
      getApi(this.state.role)
        .generateQuoteFromOrder(this.state.account.token, orderId)
        .then(() => {
          effects.notify('success', 'Quote generated')
          effects.loadOrders()
        })
        .catch((error) => {
          effects.notify('error', 'Error while generating quote')
        })
        .finally(() => {
          this.state.uploading = false
        })
    },
    toggleCollapse(effects, collapseIndex) {
      this.state.collapses = {
        ...this.state.collapses,
        [collapseIndex]: !this.state.collapses[collapseIndex],
      }
    },
    toggleModal(effects) {
      this.state.modal = !this.state.modal
    },
  },
})

const OrderAction = ({ effects, state, order }) => {
  return (
    <>
      {Order.canContinuePurchasing(order) && (
        <Button
          outline
          size="sm"
          onClick={() =>
            effects.redirectToStore({
              orderId: order.id,
            })
          }
        >
          Continue order
        </Button>
      )}
      <TransferAction state={state} effects={effects} order={order} />
      <ActionModal state={state} effects={effects} order={order} />
    </>
  )
}

const TransferAction = ({ state, effects, order }) => {
  if (order.status === 'TRANSFER_EXPECTED') {
    if (order.quotes.length === 0 && !order.isManual) {
      return (
        <Button
          color="info"
          size="sm"
          analytics-on="click"
          analytics-event="Generate quote"
          onClick={() => effects.generateQuoteFromOrder(order.id)}
        >
          Generate quote
        </Button>
      )
    } else {
      if (order.transferProofs.length > 0) {
        return (
          <Button size="sm" outline onClick={effects.toggleModal}>
            Check my documents
          </Button>
        )
      }

      if (order.purchaseOrders.length > 0 && order.invoices.length === 0) {
        return (
          <Button size="sm" outline onClick={effects.toggleModal}>
            Check my documents
          </Button>
        )
      }

      if (order.signedQuotes.length > 0 && order.proformas.length === 0) {
        return (
          <Button size="sm" outline onClick={effects.toggleModal}>
            Check my documents
          </Button>
        )
      }

      if (order.proformas.length > 0 && order.purchaseOrders.length === 0) {
        return (
          <Button size="sm" color="warning" onClick={effects.toggleModal}>
            Action required
          </Button>
        )
      }

      return (
        <Button size="sm" color="warning" onClick={effects.toggleModal}>
          Action required
        </Button>
      )
    }
  }
  return null
}

const ActionModal = ({ state, effects, order }) => (
  <Modal
    isOpen={state.modal}
    toggle={effects.toggleModal}
    size="lg"
    className="modal-lg"
  >
    <ModalHeader toggle={effects.toggleModal}>Action required</ModalHeader>
    <ModalBody>
      <Container>
        <Row>
          <Col sm="8">
            {order.isManual && order.quotes.length > 0 && (
              <Button
                color="info"
                style={{ marginBottom: '1rem' }}
                href={`${window.location.protocol}//${
                  window.location.host
                }/downloads/${
                  order.quotes[order.quotes.length - 1]
                }?terms=true`}
              >
                Download quote
              </Button>
            )}
            <Card>
              <CardHeader
                onClick={() => effects.toggleCollapse('first')}
                style={{ cursor: 'pointer' }}
                tag="h6"
              >
                {state.collapses.first ? (
                  <FaCaretDown size="22" />
                ) : (
                  <FaCaretRight size="22" />
                )}
                Transfer proof
              </CardHeader>
              <Collapse isOpen={state.collapses.first}>
                <CardBody>
                  {order.transferProofs.length > 0 && (
                    <Alert color="info">
                      Your order will be finalized very soon, after our team has
                      checked your transfer proof. In case of mistake, you can
                      still upload a new one. <br />
                      <Button
                        outline
                        color="secondary"
                        href={`${window.location.protocol}//${
                          window.location.host
                        }/downloads/${
                          order.transferProofs[order.transferProofs.length - 1]
                        }?terms=true`}
                      >
                        Check previously uploaded transfer proof
                      </Button>
                    </Alert>
                  )}
                  <Form
                    onSubmit={(e) =>
                      effects.onFormTransferProofSubmit(e, order.id)
                    }
                  >
                    <Input
                      type="file"
                      id="transferProof"
                      name="transferProof"
                      style={{ marginBottom: '0.5rem' }}
                      required
                      onChange={effects.handleChangeFile}
                    />
                    <Button
                      block
                      color="primary"
                      analytics-on="click"
                      analytics-event="Upload proof"
                      disabled={state.uploading}
                    >
                      {order.transferProofs.length > 0
                        ? 'Replace previous transfer proof'
                        : 'Upload a transfer proof'}
                    </Button>
                  </Form>
                </CardBody>
              </Collapse>
            </Card>
            <br />
            {!order.isManual && (
              <Card>
                <CardHeader
                  onClick={() => effects.toggleCollapse('second')}
                  style={{ cursor: 'pointer' }}
                  tag="h6"
                >
                  {state.collapses.second ? (
                    <FaCaretDown size="22" />
                  ) : (
                    <FaCaretRight size="22" />
                  )}
                  PROFORMA / Purchase Order
                </CardHeader>
                <Collapse isOpen={state.collapses.second}>
                  <CardBody>
                    <Button
                      color="info"
                      style={{ marginBottom: '1rem' }}
                      href={`${window.location.protocol}//${
                        window.location.host
                      }/downloads/${
                        order.quotes[order.quotes.length - 1]
                      }?terms=true`}
                    >
                      Download quote
                    </Button>
                    {order.signedQuotes.length === 0 && (
                      <Alert color="info">
                        If you need a PROFORMA invoice, you can download the
                        provided quote, and upload it back, signed.
                      </Alert>
                    )}
                    {order.signedQuotes.length > 0 && (
                      <>
                        <Alert color="info">
                          {order.proformas.length === 0 && (
                            <div>
                              Your PROFORMA invoice will be available soon,
                              after our team has checked the quote you uploaded.
                              In case of mistake, you can still upload a new
                              one.
                            </div>
                          )}
                          <Button
                            outline
                            color="secondary"
                            href={`${window.location.protocol}//${
                              window.location.host
                            }/downloads/${
                              order.signedQuotes[order.signedQuotes.length - 1]
                            }?terms=true`}
                          >
                            Check your previously uploaded signed quote.
                          </Button>
                        </Alert>
                      </>
                    )}
                    {order.proformas.length === 0 && (
                      <Form
                        onSubmit={(e) =>
                          effects.onFormSignedQuoteOrderSubmit(e, order.id)
                        }
                      >
                        <Input
                          type="file"
                          id="signedQuote"
                          name="signedQuote"
                          style={{ marginBottom: '0.5rem' }}
                          required
                          onChange={effects.handleChangeFile}
                        />
                        <Button
                          block
                          color="primary"
                          disabled={state.uploading}
                        >
                          {order.signedQuotes.length > 0
                            ? 'Replace previous signed quote'
                            : 'Upload signed quote'}
                        </Button>
                      </Form>
                    )}
                    {order.proformas.length > 0 && (
                      <>
                        <Button
                          color="info"
                          style={{ marginBottom: '1rem' }}
                          href={`${window.location.protocol}//${
                            window.location.host
                          }/downloads/${
                            order.quotes[order.quotes.length - 1]
                          }?proforma=true&terms=true`}
                          analytics-on="click"
                          analytics-event="Download proforma"
                        >
                          Download a PROFORMA Invoice
                        </Button>
                        {order.purchaseOrders.length > 0 && (
                          <Alert color="info">
                            Your XOA will be available very soon, after our team
                            has checked your purchase order. &nbsp;In case of
                            mistake, you can still upload a new one. <br />
                            <Button
                              outline
                              color="secondary"
                              href={`${window.location.protocol}//${
                                window.location.host
                              }/downloads/${
                                order.purchaseOrders[
                                  order.purchaseOrders.length - 1
                                ]
                              }?terms=true`}
                            >
                              Check previously uploaded purchase order.
                            </Button>
                          </Alert>
                        )}

                        <Form
                          onSubmit={(e) =>
                            effects.onFormPurchaseOrderSubmit(e, order.id)
                          }
                        >
                          <Input
                            type="file"
                            id="purchaseOrder"
                            name="purchaseOrder"
                            style={{ marginBottom: '0.5rem' }}
                            required
                            onChange={effects.handleChangeFile}
                          />
                          <Button
                            block
                            color="primary"
                            analytics-on="click"
                            analytics-event="Upload purchase order"
                            disabled={state.uploading}
                          >
                            {order.purchaseOrders.length > 0
                              ? 'Replace previous purchase order'
                              : 'Upload a purchase order'}
                          </Button>
                        </Form>
                      </>
                    )}
                  </CardBody>
                </Collapse>
              </Card>
            )}
          </Col>
          <Col sm="4">
            <VatesBankInfo />
          </Col>
        </Row>
      </Container>
    </ModalBody>
    <ModalFooter>
      <Button outline onClick={effects.toggleModal}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
)

export default withState(injectState(OrderAction))
