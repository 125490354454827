import {
  FaChevronUp,
  FaCreditCard,
  FaHourglassHalf,
  FaTrash,
  FaPlus,
  FaCircle
} from 'react-icons/fa'
import { MdSwapHoriz, MdWarning, MdPerson } from 'react-icons/md'
import React, { Fragment } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Collapse,
  ListGroup,
  ListGroupItem,
  Row
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { StripeProvider } from 'react-stripe-elements'

import ActionButton from '../components/action-button'
import CreditCard from './credit-card'
import { get, hasActiveSubscription } from '../../utils'
import { getApi } from '../../api'

const withState = provideState({
  initialState: () => ({
    creditCard: false,
    actionLoading: false
  }),
  effects: {
    async removeStripeCard(effects) {
      if (window.confirm(`Are you sure to remove your card ?`)) {
        const cardId = get(() => this.props.cardInfo.id)
        if (cardId) {
          this.state.actionLoading = true
          await getApi().removeStripeCard(this.props.customerId, cardId)
          await this.props.updateData()
          await effects.notify('success', 'Card successfully deleted')
          this.state.actionLoading = false
        } else {
          effects.handleError(
            new Error(`customer ${this.props.customerId} has undefined card id`)
          )
        }
      }
    },
    displayCreditCard: () => ({
      creditCard: true
    }),
    hideCreditCard: () => ({
      creditCard: false
    })
  },
  computed: {
    noPreviousCard: (_, { cardInfo }) => !Boolean(cardInfo),
    hasActiveSubscription: (state) =>
      hasActiveSubscription(state.account, state.orders),
  },
})

const CreditCardInfo = ({
  effects,
  state,
  cardInfo,
  customerId,
  stripePublicKey
}) => (
  <div style={{ margin: '20px' }}>
    <Row>
      <Col>
        <h3>CreditCard</h3>
      </Col>
    </Row>
    <hr />
    <Row>
      <Col>
        <h6 className="text-muted">
          Here you can provide up to date credit card information for your
          payments
        </h6>
      </Col>
    </Row>
    <br />
    {state.noPreviousCard ? (
      <Fragment>
        <Row style={{ marginBottom: '1rem' }}>
          <Col md="auto">
            <Button color="success" onClick={effects.displayCreditCard}>
              Add new card&nbsp;
              <FaPlus />
            </Button>
          </Col>
          <Col md="auto" style={{ alignSelf: 'center' }}>
            <span className="text-muted">
              You can add a CreditCard for your payments.
            </span>
          </Col>
        </Row>

        <Collapse isOpen={state.creditCard}>
          <br />
          <Card>
            <CardBody>
              <StripeProvider apiKey={stripePublicKey}>
                <CreditCard
                  onFinish={effects.hideCreditCard}
                  customerId={customerId}
                  mode="ADD_CARD"
                  updateData={effects.updateData}
                />
              </StripeProvider>
            </CardBody>
            {state.creditCard && (
              <CardFooter
                style={{ cursor: 'pointer', textAlign: 'center' }}
                onClick={() => effects.hideCreditCard()}
              >
                <FaChevronUp size="20" />
              </CardFooter>
            )}
          </Card>
        </Collapse>
      </Fragment>
    ) : (
      <Fragment>
        <ListGroup>
          <ListGroupItem>
            <Row>
              <Col
                md="4"
                lg="3"
                className="text-muted"
                style={{ alignSelf: 'center' }}
              >
                <MdPerson size="22" />
                &nbsp;&nbsp;Cardholder name
              </Col>
              <Col md="auto" style={{ alignSelf: 'center' }}>
                <span style={{ fontFamily: 'system-ui' }}>
                  {get(() => cardInfo.name)}{' '}
                </span>
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem>
            <Row>
              <Col
                md="4"
                lg="3"
                className="text-muted"
                style={{ alignSelf: 'center' }}
              >
                <FaCreditCard size="22" />
                &nbsp;&nbsp;Card number
              </Col>
              <Col md="auto" style={{ alignSelf: 'center' }}>
                <span style={{ fontFamily: 'system-ui' }}>
                  <FaCircle className="circle-icon" />
                  <FaCircle className="circle-icon" />
                  <FaCircle className="circle-icon" />
                  <FaCircle className="circle-icon" />
                  &nbsp; &nbsp;
                  <FaCircle className="circle-icon" />
                  <FaCircle className="circle-icon" />
                  <FaCircle className="circle-icon" />
                  <FaCircle className="circle-icon" />
                  &nbsp; &nbsp;
                  <FaCircle className="circle-icon" />
                  <FaCircle className="circle-icon" />
                  <FaCircle className="circle-icon" />
                  <FaCircle className="circle-icon" />
                  &nbsp; &nbsp;
                  {get(() => cardInfo.last4)}
                </span>
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem>
            <Row>
              <Col
                md="4"
                lg="3"
                className="text-muted"
                style={{ alignSelf: 'center' }}
              >
                <FaHourglassHalf size="22" />
                &nbsp;&nbsp;Expires in
              </Col>
              <Col md="auto" style={{ alignSelf: 'center' }}>
                <span style={{ fontFamily: 'system-ui' }}>
                  {get(() => cardInfo.exp_month)}{' '}
                  <span className="text-muted">/</span>{' '}
                  {get(() => cardInfo.exp_year)}
                </span>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
        <br />
        <Row style={{ marginBottom: '1rem' }}>
          <Col lg="auto">
            <Button color="success" onClick={effects.displayCreditCard}>
              Change my card&nbsp;
              <MdSwapHoriz size="20" />
            </Button>
          </Col>
          <Col lg="9" style={{ alignSelf: 'center' }}>
            <span className="text-muted">
              Use this if you have pointed out that your usual payment source is
              missing, expired or declined for some reason.
              <br />
              <MdWarning color="orange" size="20" />
              Replacing a card by a new one is not reversible, as we do not
              store payment information ourselves.
            </span>
          </Col>
        </Row>
        <Collapse isOpen={state.creditCard}>
          <br />
          <Card>
            <CardBody>
              <StripeProvider apiKey={stripePublicKey}>
                <CreditCard
                  onFinish={effects.hideCreditCard}
                  customerId={customerId}
                  updateData={effects.updateData}
                />
              </StripeProvider>
            </CardBody>
            {state.creditCard && (
              <CardFooter
                style={{ cursor: 'pointer', textAlign: 'center' }}
                onClick={() => effects.hideCreditCard()}
              >
                <FaChevronUp size="20" />
              </CardFooter>
            )}
          </Card>
        </Collapse>
        <br />
        {!state.hasActiveSubscription && (
          <Row style={{ marginBottom: '1rem' }}>
            <Col md="auto">
              <ActionButton
                text={
                  <span>
                    Remove my card&nbsp;
                    <FaTrash />
                  </span>
                }
                color="danger"
                action={effects.removeStripeCard}
                disabled={state.actionLoading}
              />
            </Col>
            <Col md="8" style={{ alignSelf: 'center' }}>
              <span className="text-muted">
                <MdWarning color="orange" size="20" /> Be aware, this action is
                not reversible.
              </span>
            </Col>
          </Row>
        )}
      </Fragment>
    )}
  </div>
)

export default withState(injectState(CreditCardInfo))
