import { isPurchaseLikeActive, Order } from '@vates/www-xo-utils'
import dayjs from 'dayjs'
import { Europe } from "@vates/www-xo-utils"
import { isEmpty, keys, mapValues, pick, remove } from 'lodash'

export const DATE_FORMAT = 'MMM D, YYYY'

export const plansTimeRef = {
  monthly: 0,
  oneYear: 1,
  twoYears: 2,
  threeYears: 3
}

export const removeSpaces = value => value.replace(/\s/g, '')

export const generateId = () =>
  'i' +
  Math.random()
    .toString(36)
    .slice(2)

export const getObjectLastElement = obj =>
  obj[Object.keys(obj)[Object.keys(obj).length - 1]]

export const getProductsIDs = products => {
  const productNames = keys(products)
  remove(productNames, name => name.includes('_year'))
  return productNames
}

export const get = (accessor, arg) => {
  try {
    return accessor(arg)
  } catch (error) {
    if (!(error instanceof TypeError)) {
      // avoid hiding other errors
      throw error
    }
  }
}

export const cloneObject = obj => JSON.parse(JSON.stringify(obj))

export const extractBillingInfoValidData = data => {
  const _data = pick(data, Object.keys(billingInfoTemplate))
  if (!Europe.VATNumberRequired(_data.country)) {
    delete _data.vatInComNumber
    delete _data.vatVerified
  }
  return mapValues(_data, v => (v.trim ? v.trim() : v))
}

export const billingInfoTemplate = {
  address2: '',
  address3: '',
  address4: '',
  city: '',
  company: '',
  country: '',
  dialCode: '+',
  firstName: '',
  lastName: '',
  phone: '',
  position: '',
  state: '',
  street: '',
  vatInComNumber: '',
  vatVerified: '',
  webSite: '',
  zip: '',
}

export const serializeError = error => ({
  ...error,
  code: error.code,
  message: error.message,
  name: error.name,
  stack: error.stack
})

export const trimStringInput = function(input) {
  if (typeof input !== 'string') {
    return input
  }
  return input.trimStart()
}

export const formatDate = date => {
  return dayjs(+date).format(DATE_FORMAT)
}

export const isExtendableDuration = (purchase) =>
  purchase.source.method === 'transfer' &&
  purchase.paid &&
  (purchase.end && +purchase.end > Date.now()) &&
  isEmpty(purchase.extended) &&
  isEmpty(purchase.extendedByOrder) &&
  purchase.id !== 'xcpng-standard' &&
  purchase.id !== 'xcpng-enterprise'

export const hasActivePurchaseSubscription = (account) => {
  const purchaseSubscription =
    account &&
    account.purchasing &&
    Object.values(account.purchasing.purchases).some(
      (purchase) =>
        purchase.source.method === 'stripe' && isPurchaseLikeActive(purchase)
    )

  const resellingSubscription =
    account &&
    account.reselling &&
    Object.values(account.reselling.purchases).some(
      (purchase) =>
        purchase.source.method === 'stripe' && isPurchaseLikeActive(purchase)
    )

  return purchaseSubscription || resellingSubscription
}

export const hasActiveOrderSubscription = (orders) =>
  orders.some((order) => order.status === Order.ORDER_STATUS.UP)

export const hasActiveSubscription = (account, orders) =>
  hasActivePurchaseSubscription(account) || hasActiveOrderSubscription(orders)