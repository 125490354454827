import React from 'react'
import { Card, Nav, NavItem, Col, Row } from 'reactstrap'
import {
  FaCog,
  FaCogs,
  FaFileInvoice,
  FaFolder,
  FaHandshake,
  FaRegCreditCard,
  FaUser,
} from 'react-icons/fa'
import { MdContactMail, MdSupport } from 'react-icons/md'
import { NavLink } from 'react-router-dom'

import './style.css'

const activeStyle = {
  fontWeight: '450',
  color: 'white',
  backgroundColor: 'black',
  border: 'none',
  pointerEvents: 'none',
}

const Menu = ({ role, children }) => (
  <Card
    style={{
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    }}
  >
    <Row>
      <Col md="3">
        <Card className="h-100 menu">
          <p>
            profile settings <FaCogs className="float-right" size="24" />
          </p>
          <Nav vertical>
            <NavItem>
              <NavLink activeStyle={activeStyle} to="/billingInfo">
                <FaUser size="22" /> &nbsp;Contact information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeStyle={activeStyle} to="/purchases">
                <FaFolder size="22" /> &nbsp;Purchases management
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeStyle={activeStyle} to="/invoices">
                <FaFileInvoice size="22" /> &nbsp;Invoices
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeStyle={activeStyle} to="/creditCard">
                <FaRegCreditCard size="22" /> &nbsp; Credit card information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                activeStyle={activeStyle}
                to="/additionalEmailRecipients"
              >
                <MdContactMail size="22" /> &nbsp; Additional Email Recipients
              </NavLink>
            </NavItem>
            {role === 'reseller' && (
              <NavItem>
                <NavLink activeStyle={activeStyle} to="/partner">
                  <FaHandshake size="22" /> &nbsp; Partner
                </NavLink>
              </NavItem>
            )}
            {/* {role === 'reseller' && (
              <NavItem>
                <NavLink activeStyle={activeStyle} to="/partner-portal">
                  <FaHandshake size="22" /> &nbsp; Partner portal
                </NavLink>
              </NavItem>
            )} */}
            <NavItem>
              <NavLink activeStyle={activeStyle} to="/support">
                <MdSupport size="22" /> &nbsp; Support
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeStyle={activeStyle} to="/account-management">
                <FaCog size="22" /> &nbsp; Account management
              </NavLink>
            </NavItem>
          </Nav>
        </Card>
      </Col>
      <Col md="9">{children}</Col>
    </Row>
  </Card>
)

export default Menu
