import React from 'react'
import dayjs from 'dayjs'
import { Col, Row } from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import * as Utils from '@vates/www-xo-utils'

const withState = provideState({
  effects: {
    initialize() {
      if (this.state.role !== 'reseller') {
        window.location.replace('/#/billingInfo')
      }
    },
  },
})

const Partner = ({ state }) => {
  if (state.role === 'reseller') {
    return (
      <div style={{ margin: '20px' }}>
        <Row>
          <Col>
            <h3>Partner</h3>
          </Col>
        </Row>
        <hr />
        <div>
          You are partner since{' '}
          {dayjs(state.account.role.reseller.granted[0]).format(Utils.DATE_FORMAT)}
        </div>
        <div style={{ marginTop: '1rem' }}>
          <a
            href={state.config.partnerPortalFrontEndUrl}
            className="btn btn-outline-secondary"
          >
            Go to Partner portal
          </a>
        </div>
      </div>
    )
  }
  return null
}

export default withState(injectState(Partner))
