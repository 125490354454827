import React from 'react'
import { Button } from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import ButtonSpinner from '../../imgs/spinner.gif'

const withState = provideState({
  initialState: () => ({
    loading: false
  }),
  effects: {
    initialize: effects => (_, { form }) => {
      if (form) {
        document
          .getElementById(form)
          .addEventListener('submit', effects.executeAction)
      }
    },
    async executeAction (effects, event) {
      if (event) {
        event.preventDefault()
      }
      if (this.props.action) {
        this.state.loading = true
        try {
          await this.props.action(event)
          if (this.props.afterEffect && typeof this.props.afterEffect === "function") {
            await this.props.afterEffect()
          }
        } catch (error) {
          effects.handleError(error) // Extern
        }
        this.state.loading = false
      }
    }
  },
  finalize: effects => (_, { form }) => {
    if (form) {
      document
        .getElementById(form)
        .removeEventListener('submit', effects.executeAction)
    }
  }
})

const ActionButton = ({
  block,
  color,
  classNames,
  effects,
  disabled,
  outline,
  state,
  text,
  width,
  style,
  form
}) => {
  const props = {
    disabled: state.loading || disabled,
    outline,
    style: { width: `${width}px`, ...style }
  }

  if (color) {
    props.color = color
  } else {
    props.color = 'dark'
  }

  if (form) {
    props.type = 'submit'
  } else {
    props.onClick = effects.executeAction
  }

  return (
    <Button {...props} className={classNames} block={block}>
      {text} &nbsp;{' '}
      {state.loading && (
        <img src={ButtonSpinner} alt="button spinner" height="20" width="20" />
      )}
    </Button>
  )
}

export default withState(injectState(ActionButton))
