import { request } from './fetch-api'
import * as XrApi from './xr-api'
import * as XpApi from './xp-api'
import * as XwApi from './xw-api'

export const getApi = (role = undefined) => {
  if (role === 'reseller') {
    return XrApi
  }
  if (role === 'purchaser') {
    return XpApi
  }
  return XwApi
}

export const generateCrossAuthToken = (customerId, eat) =>
  request('/crossplatform/api', 'generateCrossAuthToken', {
    customerId,
    eat
  })

export const verifyCrossAuthToken = crossAuthToken =>
  request('/crossplatform/api', 'verifyCrossAuthToken', {
    crossAuthToken
  })
