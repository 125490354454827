import React from 'react'
import LoadingIcon from '../../imgs/spinner.gif'

const Loader = () => (
  <div style={{textAlign: "center"}}>
    <img src={LoadingIcon} alt="Loading..." height="100" width="100" />
  </div>
)

export default Loader
