import { FaEnvelope, FaPlus, FaTrash } from 'react-icons/fa'
import React from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { map } from 'lodash'
import { injectState, provideState } from 'reaclette'

import { getApi } from '../../api'

const withState = provideState({
  initialState: () => ({
    email: '',
    removalModalOpen: false,
    emailToRemove: ''
  }),
  effects: {
    handleEmail: (_, { target: { value } }) => ({
      email: value
    }),
    toggleModal() {
      this.state.removalModalOpen = !this.state.removalModalOpen
    },
    removeInvoiceEmail: (_, email) => ({
      emailToRemove: email,
      removalModalOpen: true
    }),
    async addInvoiceEmail(effects, event) {
      event.preventDefault()
      try {
        await getApi().addInvoiceEmail(this.props.customerId, this.state.email)
        await this.props.updateData()
        this.state.email = ''
        effects.notify('success', 'Email successfully added')
      } catch (error) {
        effects.handleError(error)
      }
    },
    async onRemovalModalClose(effects, answer) {
      if (answer) {
        await getApi().removeInvoiceEmail(
          this.props.customerId,
          this.state.emailToRemove
        )
        await this.props.updateData()
        await effects.notify('success', 'Email successfully deleted')
      }
      this.state.removalModalOpen = false
      this.state.emailToRemove = undefined
    }
  }
})

const AdditionalEmailRecipients = ({
  additionalEmailRecipients,
  effects,
  state
}) => (
  <div style={{ margin: '20px' }}>
    <Row>
      <Col>
        <h3>Additional email recipients</h3>
      </Col>
    </Row>
    <hr />
    <Row>
      <Col>
        <h6 className="text-muted">
          Here you can add additional recipients for documents or information we
          may send to you.
        </h6>
      </Col>
    </Row>
    <br />
    <Row>
      <Col md="6">
        <Card body>
          <h3 className="text-muted">Add recipient</h3>
          <hr />
          {additionalEmailRecipients ? (
            <ListGroup>
              {map(additionalEmailRecipients, email => (
                <ListGroupItem className="listGroupItem" key={email}>
                  <FaEnvelope color="gray" />
                  &nbsp;&nbsp;{email}
                  <Button
                    size="sm"
                    color="light"
                    className="float-right"
                    onClick={() => effects.removeInvoiceEmail(email)}
                  >
                    <FaTrash color="red" />
                  </Button>
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <p className="text-muted text-center">No recipients added yet</p>
          )}
          <br />
          <Form onSubmit={effects.addInvoiceEmail}>
            <FormGroup>
              <Label for="country">Email address*</Label>
              <InputGroup>
                <Input
                  name="email"
                  id="email"
                  onChange={effects.handleEmail}
                  required
                  type="email"
                  value={state.email}
                />
                <InputGroupAddon addonType="append">
                  <Button type="submit" color="success">
                    Add&nbsp; <FaPlus />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form>
        </Card>
      </Col>
    </Row>
    <Modal isOpen={state.removalModalOpen} toggle={effects.toggleModal}>
      <ModalHeader toggle={effects.toggleModal}>Confirmation</ModalHeader>
      <ModalBody>
        Are you sure you want to remove <b>{state.emailToRemove}</b> from your
        invoice recipients ?`
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={effects.toggleModal}>
          Cancel
        </Button>
        <Button color="danger" onClick={effects.onRemovalModalClose}>
          Remove
        </Button>
      </ModalFooter>
    </Modal>
  </div>
)

export default withState(injectState(AdditionalEmailRecipients))
