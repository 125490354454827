import React, { Fragment } from 'react'
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import { getApi } from '../../../api'
import { MdWarning } from 'react-icons/md'

const withState = provideState({
  initialState: () => ({
    reason: undefined,
    details: undefined,
    modal: false,
    loading: false
  }),
  effects: {
    async stopRenewal(effects, e) {
      e.preventDefault()
      try {
        this.state.loading = true
        await getApi(this.state.role).stopRenewal(this.state.account.token, this.props.orderId, this.state.reason, this.state.details)
        effects.toggleModal()
        effects.notify('success', 'Payment has been successfully cancelled.')
        await effects.loadOrders()
      } catch (error) {
        await effects.handleError(error)
      } finally {
        this.state.loading = false
      }
    },
    toggleModal(effects) {
      this.state.modal = !this.state.modal
    },
    handleChange(effects, e) {
      this.state[e.target.name] = e.target.value
    }
  }
})

const StopRenewal = ({ effects, state, orderId }) => {
  return (
    <Fragment>
      <Button size="sm" outline color="danger" onClick={effects.toggleModal} id={orderId}>
        Stop renewal
      </Button>

      <Modal isOpen={state.modal} size="lg" className="modal-lg">
        <Form onSubmit={e => effects.stopRenewal(e)}>
          <ModalHeader toggle={effects.toggleModal}>
            Subscription cancelation
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Alert color="warning">
                <MdWarning size="24" /> Cancelling the plan and recurring bills will interrupt your subscription at the end of the current paid period.
              </Alert>
              <Label for="reason">
                <strong>Why are you cancelling your subscription?</strong>
              </Label>
              <Input
                type="select"
                name="reason"
                id="reason"
                required
                onChange={effects.handleChange}
              >
                <option value="">-- select one --</option>
                <option value="issue">
                  You encountered a critical issue with XO we were unable to
                  solve.
                </option>
                <option value="expensive">
                  XO is too expensive and does not fit in your budget anymore.
                </option>
                <option value="changeHypervisor">
                  You have changed your Hypervisor therefore XO is not
                  compatible anymore.
                </option>
                <option value="changeStopActivity">
                  Your company changes/stops its activity.
                </option>
                <option value="other">Another reason.</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="details">
                <strong>
                  Can you provide additional details about the reason why you
                  are leaving?
                </strong>
              </Label>
              <Input
                type="textarea"
                name="details"
                id="details"
                required
                onChange={effects.handleChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button outline onClick={effects.toggleModal}>
              I changed my mind and want to keep my subscription
            </Button>
            <Button
              color="danger"
              disabled={state.loading}
              analytics-on="click"
              analytics-event="cancel subscription"
            >
              Confirm and cancel your subscription
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  )
}

export default withState(injectState(StopRenewal))
