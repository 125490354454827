import React from 'react'
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { MdWarning } from 'react-icons/md'
import { Order } from '@vates/www-xo-utils'

import { getApi } from '../../../api'
import { getEndUserEmail } from '../../../api/dataStore'

const existEndUser = (order) => order && order.endUser && order.endUser.token

const withState = provideState({
  initialState: () => ({
    email: '',
    loading: false,
    modal: false
  }),
  effects: {
    bindEndUser(effects, orderId) {
      this.state.loading = true
      getApi(this.state.role)
        .bindEndUserToOrder(this.state.account.token, this.state.email, orderId)
        .then(() => {
          effects.notify(
            'success',
            'Your product has been successfully attached to your end user.'
          )
          effects.loadOrders()
        })
        .catch(error => {
          effects.notify('error', error.message)
        })
        .finally(() => {
          this.state.loading = false
        })
    },
    handleEmailChange(effects, e) {
      this.state.email = e.target.value
    },
    toggleModal(effects) {
      this.state.modal = !this.state.modal
    },
  },
  computed: {
    endUserEmail: {
      async get(_state, props) {
        if (existEndUser(props.order)) {
          try {
            const { email } = await getEndUserEmail(props.order.endUser.token)
            return email
          } catch (error) {
            return 'Cannot get end user email'
          }
        }
      },
      placeholder: 'loading...',
    },
  },
})

const OrderEndUser = ({ effects, state, order }) => {
  if (order.endUser && order.endUser.token) {
    return state.endUserEmail
  }

  if (!Order.canBindEndUserToOrder(order)) {
    return null
  }

  return (
    <>
      <Button outline size="sm" onClick={effects.toggleModal}>
        Bind to user
      </Button>

      <Modal isOpen={state.modal}>
        <Form
          onSubmit={e => {
            e.preventDefault()
            effects.bindEndUser(order.id)
          }}
        >
          <ModalHeader toggle={effects.toggleModal}>
            Unreversible binding operation
          </ModalHeader>
          <ModalBody>
            <Alert color="warning">
              <MdWarning size="24" /> Please check carefully the spelling of the
              email address before confirmation. You won't be able to change the
              address without support from our team.
            </Alert>
            <Input
              id="email"
              name="email"
              onChange={effects.handleEmailChange}
              placeholder="Email *"
              required
              style={{ marginBottom: '0.5rem' }}
              type="email"
              value={state.email}
            />
          </ModalBody>
          <ModalFooter>
            <Button outline onClick={effects.toggleModal}>
              Cancel
            </Button>
            <Button color="success" type="submit" disabled={state.loading}>
              Bind to user
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  )
}

export default withState(injectState(OrderEndUser))
