import React, { Fragment } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { injectState } from 'reaclette'
import { getSubCompany } from '@vates/www-xo-utils'

const BANK = {
  FR: {
    EUR: {
      name: 'BNP Paribas Arc Alpin Entreprise',
      iban: 'FR76 3000 4024 7500 0107 0271 308',
      bic: 'BNPAFRPPXXX',
    },
    USD: {
      name: 'BNP Paribas Arc Alpin Entreprise',
      iban: 'FR76 3000 4024 7500 0107 0271 308',
      bic: 'BNPAFRPPXXX',
    },
  },
  IT: {
    EUR: {
      name: 'BNP Paribas Arc Alpin Entreprise',
      iban: 'IT18 E010 3002 0010 0000 0924 030',
    },
    USD: {
      name: 'BNP Paribas Arc Alpin Entreprise',
      iban: 'IT18 E010 3002 0010 0000 0924 030',
    },
  },
}

const VatesBankInfo = ({ state }) => {
  const subCompany = (state.account && getSubCompany(state.account)) || 'FR'
  const currency =
    (state.account &&
      state.account.billingInfo &&
      state.account.billingInfo.currency) ||
    'USD'
  const bankInfo = BANK[subCompany][currency]

  return (
    <Card>
      <CardHeader tag="h6">BANK DETAILS</CardHeader>
      <CardBody>
        <h6>
          <strong>VATES SAS</strong>
        </h6>
        <p>
          17 RUE AIME BEREY <br />
          38000 GRENOBLE
        </p>
        <p>
          <strong>Bank name: </strong>
          <br />
          {bankInfo.name}
          <br />
          <strong>IBAN: </strong>
          <br />
          {bankInfo.iban}
          <br />
          {bankInfo.bic && (
            <Fragment>
              <strong>BIC: </strong>
              <br />
              {bankInfo.bic}
            </Fragment>
          )}
        </p>
      </CardBody>
    </Card>
  )
}

export default injectState(VatesBankInfo)
