import * as ApplicationError from '@vates/xw-api-errors'

const handleJsonRpcResponse = async response => {
  const { error, result } = await response.json()
  if (error) {
    const code = +error.code
    const ErrorType = ApplicationError.getErrorFromCode(code)
    if (!ErrorType) {
      // Unknown error (not known between server end client)
      throw new ApplicationError.CriticalError(
        `Unexpected error: ${error.message}`
      )
    }
    throw new ErrorType(error.message)
  } else {
    return result
  }
}

export default handleJsonRpcResponse
