import React from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { HandledErrors, AuthenticationFailed } from '@vates/xw-api-errors'
import { FaCheck, FaTimes } from 'react-icons/fa'

import { getApi } from '../../api'
import ActionButton from '../components/action-button'

const initState = { currentPassword: '', newPassword: '', confirmPassword: '' }

const withState = provideState({
  initialState: () => ({ ...initState }),
  effects: {
    async handlePasswordChange(_, { target }) {
      this.state[target.name] = target.value
    },
    resetState: () => ({ ...initState }),
    async updatePassword(effects, event) {
      event.preventDefault()
      try {
        try {
          await getApi().changePassword(
            this.props.customerId,
            this.state.currentPassword,
            this.state.newPassword
          )
          await effects.notify('success', 'Password successfully updated')
          effects.resetState()
        } catch (error) {
          if (error instanceof AuthenticationFailed) {
            throw new HandledErrors('Invalid current password')
          } else {
            throw error
          }
        }
      } catch (error) {
        effects.handleError(error)
      }
    }
  },
  computed: {
    isSamePassword: state => state.newPassword === state.confirmPassword
  }
})

const ChangePassword = ({ effects, state }) => (
  <Card>
    <CardHeader tag="h5">Change password</CardHeader>
    <CardBody>
      <Form id="change-password-form">
        <Row form>
          <Col md="4">
            <FormGroup>
              <Label for="currentPassword">Current password*</Label>
              <Input
                name="currentPassword"
                id="currentPassword"
                onChange={effects.handlePasswordChange}
                required
                type="password"
                value={state.currentPassword}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label for="newPassword">New password*</Label>
              <Input
                name="newPassword"
                id="newPassword"
                onChange={effects.handlePasswordChange}
                required
                type="password"
                value={state.newPassword}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label for="confirmPassword">Confirm password*</Label>
              <InputGroup>
                <Input
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={effects.handlePasswordChange}
                  required
                  type="password"
                  value={state.confirmPassword}
                />
                <InputGroupAddon addonType="append">
                  {state.confirmPassword && state.isSamePassword && (
                    <InputGroupText className="bg-success text-white">
                      <FaCheck size="22" />
                    </InputGroupText>
                  )}
                  {!state.isSamePassword && (
                    <InputGroupText className="bg-danger text-white">
                      <FaTimes size="22" />
                    </InputGroupText>
                  )}
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <ActionButton
          disabled={!state.isSamePassword}
          classNames="float-right"
          color="success"
          form="change-password-form"
          text="Update password"
          action={effects.updatePassword}
        />
      </Form>
    </CardBody>
  </Card>
)

export default withState(injectState(ChangePassword))
