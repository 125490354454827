import React from 'react'
import {
  Card,
  CardBody,
  CardHeader,
 } from 'reactstrap'

const Preferences = () => (
  <Card>
    <CardHeader tag="h5">Preferences</CardHeader>
    <CardBody>
      To manage your subscription preferences for our newsletters, simply use the 'unsubscribe' link provided in
      every correspondence we deliver.
    </CardBody>
  </Card>
)

export default Preferences
