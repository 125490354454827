import React from 'react'
import { injectState, provideState } from 'reaclette'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import ActionButton from '../components/action-button'
import { getApi } from '../../api'

const withState = provideState({
  initialState: () => ({
    currentPassword: '',
  }),
  effects: {
    async handleInputChange(_, { target }) {
      this.state[target.name] = target.value
    },
    async requestSupportStart(effects) {
      try {
        await getApi().requestSupportStart(
          this.state.customerId,
          this.state.currentPassword
        )
        await effects.notify('success', 'Helpdesk account created')
        effects.loadContext()
      } catch (error) {
        effects.handleError(error)
      }
    },
  },
})

const RequestSupport = ({ effects, state }) => (
  <Card>
    <CardHeader tag="h5">Helpdesk account creation</CardHeader>
    <CardBody>
      <p>
        Confirm your identity before we proceed on creating your helpdek account
        on help.vates.fr
      </p>
      <Form id="request-support-form">
        <Row form>
          <Col md="4">
            <FormGroup>
              <Label for="currentPassword">Current password</Label>
              <Input
                name="currentPassword"
                id="currentPassword"
                onChange={effects.handleInputChange}
                required
                type="password"
                placeholder="***********"
                value={state.currentPassword}
              />
            </FormGroup>
          </Col>
        </Row>
        <ActionButton
          classNames="float-right"
          color="success"
          form="request-support-form"
          text="Create Helpdesk account"
          action={effects.requestSupportStart}
        />
      </Form>
    </CardBody>
  </Card>
)

export default withState(injectState(RequestSupport))
