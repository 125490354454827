import React from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import { getApi } from '../../api'
import ActionButton from '../components/action-button'
import VatesLogo from '../../imgs/vates-logo.png'

const withState = provideState({
  initialState: () => ({
    password: '',
    loading: true
  }),
  effects: {
    async initialize() {
      await this.props.decodeJwt(this.props.match.params.token)
      this.state.loading = false
    },
    async setPassword(effects) {
      this.state.loading = true
      try {
        await getApi().setPasswordFromRecovery(
          this.props.match.params.token,
          this.state.password
        )
        await effects.notify('success', 'Password changed')
        this.props.history.push('/login')
      } catch (error) {
        throw error
      } finally {
        this.state.loading = false
      }
    },
    handlePassword: (_, { target: { value } }) => state => ({
      ...state,
      password: value
    })
  }
})

const AccountRecovery = ({ effects, state }) => (
  <Row style={{ marginTop: '50px' }}>
    <Col sm={{ size: 4, offset: 4 }}>
      <Card body>
        <CardTitle>
          <div className="text-center">
            <img src={VatesLogo} alt="Vates" width="100" />
            <div className="card-title">Reset your password</div>
          </div>
        </CardTitle>
        <CardBody>
          <br />
          <Form id="recovery-form">
            <FormGroup>
              <Input
                type="password"
                placeholder="Choose your account password"
                value={state.password}
                onChange={effects.handlePassword}
                required
              />
            </FormGroup>
            <ActionButton
              action={effects.setPassword}
              block={true}
              color="success"
              disabled={state.password === '' || state.loading}
              form="recovery-form"
              text="Set password"
            />
          </Form>
        </CardBody>
      </Card>
    </Col>
  </Row>
)

export default withState(injectState(AccountRecovery))
