import React from 'react'
import { Col, Container, Row } from 'reactstrap'

class Legal extends React.Component {
  componentDidMount() {
    this.scroll()
  }

  componentDidUpdate() {
    this.scroll()
  }

  scroll() {
    if (this.props && this.props.location && this.props.location.hash) {
      const element = document.querySelector(this.props.location.hash)
      if (element) {
        element.scrollIntoView()
      }
    }
  }

  render() {
    return (
      <>
        <Container className="terms">
          <h1>End User Terms and Conditions</h1>
          <div className="terms-date">Last Updated Jul. 24th, 2017</div>
          <Row>
            <Col>
              <h2>Introduction</h2>
              <p>
                Vates, SAS (“Vates”, “we”, “us” or “our”) welcomes you to
                xen-orchestra.com (the “Website”). These terms and conditions of
                service (with Vates’s Privacy Policy, the “Terms of Service” or
                “Agreement”) govern your use of the Website and the services,
                features, content or applications operated by Vates (together
                with the Website, products like XOA, download of XOA and the Pro
                Support, the “Services”), and provided to the Subscriber (the
                “Subscriber”, “you” or “your”). Please read these Terms of
                Service carefully before using the Services. These Terms of
                Service apply to all users of the Services, including without
                limitation any sub-users. Using the Services in any manner
                constitutes your acceptance and agreement to be bound by these
                Terms of Service, and all other operating rules, policies and
                procedures that may be published from time to time on the
                Website by us, each of which is incorporated by reference and
                each of which may be updated from time to time without notice to
                you. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE, YOU MAY NOT
                USE THE SERVICES AND YOU SHOULD EXIT THE WEBSITE AND STOP USE OF
                THE SERVICES IMMEDIATELY. Vates reserves the right, at any time
                and from time to time, to amend or to modify these Terms of
                Service without prior notice to you, provided that if any such
                alterations constitute a material change to these Terms of
                Service, Vates will notify you by posting an announcement on the
                Website. Amendments and modifications shall take effect
                immediately when posted on the Website. By continuing to access
                or use the Services after any such amendments or modifications,
                you agree to be bound by such amended or modified Terms of
                Service. For this reason, we encourage you to review the Terms
                of Service whenever you use the Services. If you do not agree to
                any change to these Terms of Services, then you must immediately
                stop using the Services.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Eligibility & Registration</h2>
              <p>
                The Services are not targeted towards, nor intended for use by,
                anyone under the age of 13. By using the Services, you represent
                and warrant that you are 13 years of age or older. If you are
                under the age of 13, you may not, under any circumstances or for
                any reason, use the Services. We may, in our sole discretion,
                refuse to offer the Services to any person or entity and change
                its eligibility criteria at any time. You are solely responsible
                for ensuring that these Terms of Service are in compliance with
                all laws, rules and regulations applicable to you and the right
                to access the Services is revoked where these Terms of Service
                or use of the Services is prohibited or to the extent offering,
                sale or provision of the Services conflicts with any applicable
                law, rule or regulation. Further, the Services are offered only
                for your use, and not for the use or benefit of any third party.
              </p>
              <p>
                To sign up for the Services, you must register for an account on
                the Services (an “Account”). You must provide accurate and
                complete information and keep your Account information updated.
                You shall not: (i) select or use as a username a name of another
                person with the intent to impersonate that person; (ii) use as a
                username a name subject to any rights of a person other than you
                without appropriate authorization; or (iii) use, as a username,
                a name that is otherwise offensive, vulgar or obscene. You are
                solely responsible for the activity that occurs on your Account,
                regardless of whether the activities are undertaken by you, your
                employees or a third party (including your contractors or
                agents), and for keeping your Account password secure. You may
                never use another person’s user account or registration
                information for the Services without permission. You must notify
                us immediately of any change in your eligibility to use the
                Services (including any changes to or revocation of any licenses
                from state authorities), breach of security or unauthorized use
                of your Account. You should never publish, distribute or post
                login information for your Account. You shall have the ability
                to delete your Account, either directly or through a request
                made to one of our employees or affiliates. Vates will not be
                liable for any loss or damage as a result of your failure to
                provide us with accurate information or to keep your Account
                secure.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Notification Regarding these Terms of Service</h2>
              <p>
                Subscribers shall notify all persons who receive access to the
                Services of the provisions of these Terms of Service, and shall
                inform them that the terms of these Terms of Service are binding
                upon them.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Payments and Billing</h2>
              <p>
                Vates accepts major credit cards, debit cards, Wire transfer and
                Bank check. Virtual credit cards and gift cards typically will
                not be accepted. Out of process purchase may be arranged by
                sending an email at{' '}
                <a href="mailto:contact@vates.fr">contact@vates.fr</a>, note
                that additional fees may apply for such situation. Please note
                that any payment terms presented to you in the process of using
                or signing up for paid Services are deemed part of this
                Agreement.
              </p>
              <p>
                We use third-party payment processors (the “Payment Processors”)
                to bill you through a payment account linked to your Account on
                the Services (your “Billing Account”) for use of the paid
                Services. The processing of payments will be subject to the
                terms, conditions and privacy policies of the Payment Processors
                in addition to this Agreement. We are not responsible for error
                by the Payment Processors. By choosing to use paid Services, you
                agree to pay us, through the Payment Processors, all charges at
                the prices then in effect for any use of such paid Services in
                accordance with the applicable payment terms and you authorize
                us, through the Payment Processors, to charge your chosen
                payment provider (your “Payment Method”). You agree to make
                payment using that selected Payment Method. We reserve the right
                to correct any errors or mistakes that it makes even if it has
                already requested or received payment.
              </p>
              <p>
                The term of this Agreement shall be monthly (or yearly,
                depending on the option chosen when the purchase is done), to
                commence on the date that the Subscriber signs up electronically
                for the Services by creating an Account with an email address.
                All invoices are denominated, and Subscriber must pay, in U.S.
                Dollars. Subscribers are typically billed monthly on or about
                the day of the start to each period, with payment due no later
                than ten (10) days past the invoice date. On rare occasions, a
                Subscriber may be billed an amount up to the Subscriber's
                current balance in an effort to verify the authenticity of the
                Subscriber's account information. This process ensures that
                Subscribers without a payment history are not subjected to
                additional scrutiny. Subscribers are entirely responsible for
                the payment of all taxes. For specific pricing policies, please
                refer to{' '}
                <a href="https://xen-orchestra.com/#/pricing">
                  https://xen-orchestra.com/#/pricing
                </a>
                .
              </p>
              <p>
                Some of the paid Services may consist of an initial period, for
                which there is a one-time charge, followed by recurring period
                charges as agreed to by you. By choosing a recurring payment
                plan, you acknowledge that such Services have an initial and
                recurring payment feature and you accept responsibility for all
                recurring charges prior to cancellation. WE MAY SUBMIT PERIODIC
                CHARGES (E.G., MONTHLY) WITHOUT FURTHER AUTHORIZATION FROM YOU,
                UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED BY
                US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO
                CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES
                SUBMITTED BEFORE WE REASONABLY COULD ACT. TO TERMINATE YOUR
                AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD, GO TO YOUR MEMBER
                ZONE ON OUR WEBISTE.
              </p>
              <p>
                YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR
                YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION
                TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE
                (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR
                CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR
                OUR PAYMENT PROCESSORS IF YOUR PAYMENT METHOD IS CANCELED (E.G.,
                FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH
                OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR
                USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE
                AT{' '}
                <a href="https://xen-orchestra.com/#/login?source=member">
                  https://xen-orchestra.com/#/login?source=member
                </a>
                . IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU
                AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID
                SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED
                YOUR PAID SERVICES AS SET FORTH ABOVE.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Early Access</h2>
              <p>
                “Early Access” or “Beta” are terms referring to XOA version
                which are not feature complete, but enough advanced to be used
                with working features and/or preview of working features. As
                only using the XAPI, Xen Orchestra is an interface, and ONLY USE
                this API to communicate to XenServer or XAPI capable hosts thus
                limiting risks. By registering to these kind of versions, you
                got access to unlimited ticket support. depending of your
                payment method (Wiretransfer can't be use for less than 1 YEAR).
                With a Credit Card Subscription, you can abort the plan after
                the initial minimal period (1 MONTH).
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Warranty Disclaimer</h2>
              <p>
                THE SERVICES AND CONTENT ARE PROVIDED “AS IS”, “AS AVAILABLE”
                AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING,
                BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE,
                NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
                PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE
                OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WE,
                AND OUR DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND
                CONTENT PROVIDERS DO NOT WARRANT THAT: (I) THE SERVICES WILL BE
                SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY
                DEFECTS OR ERRORS WILL BE CORRECTED; (III) ANY CONTENT OR
                SOFTWARE AVAILABLE AT OR THROUGH THE SERVICES IS FREE OF VIRUSES
                OR OTHER HARMFUL COMPONENTS; OR (IV) THE RESULTS OF USING THE
                SERVICES WILL MEET YOUR REQUIREMENTS. YOUR USE OF THE SERVICES
                IS SOLELY AT YOUR OWN RISK.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Limitation of Liability</h2>
              <p>
                IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS,
                PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER
                CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL
                OR EQUITABLE THEORY WITH RESPECT TO THE SERVICES (I) FOR ANY
                LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS
                OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE,
                COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER,
                SUBSTITUTE GOODS OR SERVICES (HOWEVER ARISING), (II) FOR ANY
                BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE
                SOURCE OF ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN
                EXCESS OF (IN THE AGGREGATE) OF FEES PAID TO US FOR THE
                PARTICULAR SERVICES DURING THE IMMEDIATELY PREVIOUS ONE MONTH
                PERIOD, EVEN IF VATES HAD BEEN ADVISED OF, KNEW, OR SHOULD HAVE
                KNOWN, OF THE POSSIBILITY THEREOF. SUBSCRIBER ACKNOWLEDGES THAT
                THE FEES PAID BY HIM OR HER REFLECT THE ALLOCATION OF RISK SET
                FORTH IN THIS AGREEMENT AND THAT VATES WOULD NOT ENTER INTO THIS
                AGREEMENT WITHOUT THESE LIMITATIONS. SUBSCRIBER HEREBY WAIVES
                ANY AND ALL CLAIMS AGAINST VATES ARISING OUT OF SUBSCRIBER'S
                PURCHASE OR USE OF THE SERVICES, OR ANY CONDUCT OF VATES’S
                DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR REPRESENTATIVES. YOUR
                SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION
                WITH THE SERVICES OR ANY OTHER GRIEVANCE SHALL BE YOUR
                TERMINATION AND DISCONTINUATION OF ACCESS TO OR USE OF THE
                SERVICES.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Backup</h2>
              <p>
                Subscriber is solely responsible for the preservation of
                Subscriber's data which Subscriber saves via its XOA products
                (the “Data”). Even with respect to Data as to which Subscriber
                contracts for backup services provided by Vates, Vates shall
                have no responsibility to preserve Data. Vates shall have no
                liability for any Data that may be lost, or unrecoverable, by
                reason of Subscriber’s failure to backup its Data.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Publicity</h2>
              <p>
                Each Subscriber is permitted to state publicly that such
                Subscriber is a Subscriber of the Services. Each Subscriber
                agrees that Vates may include such Subscriber’s name and
                trademarks in a list of Vates Subscriber, online or in
                promotional materials. Each Subscriber also agrees that Vates
                may verbally reference such Subscriber as a Subscriber of the
                Services. Subscriber may opt out of the provisions in this
                Section by e-mailing a request to{' '}
                <a href="mailto:contact@vates.fr">contact@vates.fr</a>.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Termination</h2>
              <p>
                Vates reserves the right, in our sole discretion, to terminate
                your access to all or any part of the Services at any time, with
                or without notice, effective immediately, including but not
                limited to as a result of your violation of any of these Terms
                of Service or any law. Any such termination may result in the
                forfeiture and destruction of information associated with your
                Account. Vates may provide prior notice of the intent to
                terminate Services to you if such notice will not, in Vates's
                discretion, run counter to the intents and purposes of these
                Terms of Service. Any fees paid hereunder are non-refundable and
                any fees owed to Vates before such termination shall be
                immediately due and payable, including any liabilities that may
                have been incurred prior to termination such as Vates’s costs
                for collection (including attorneys’ fees) of any such charges
                or other liabilities. Upon termination, any and all rights
                granted to Subscriber by this Agreement will immediately be
                terminated, and Subscriber shall promptly discontinue all use of
                the Services. If you wish to terminate your Account, you may do
                so by following the instructions on the Website or through the
                Services. All provisions of these Terms of Service which by
                their nature should survive termination shall survive
                termination, including, without limitation, licenses of User
                Content, ownership provisions, warranty disclaimers, indemnity
                and limitations of liability.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Miscellaneous Provisions</h2>
              <p>
                Neither you nor Vates shall be liable for nonperformance of the
                terms herein to the extent that either you or Vates are
                prevented from performing as a result of any act or event which
                occurs and is beyond your or Vates’s reasonable control,
                including, without limitation, acts of God, war, unrest or riot,
                strikes, any action of a governmental entity, weather,
                quarantine, fire, flood, earthquake, explosion, utility or
                telecommunications outages, Internet disturbance, or any
                unforeseen change in circumstances, or any other causes beyond
                either party’s reasonable control. The party experiencing the
                force majeure shall provide the other party with prompt written
                notice thereof and shall use reasonable efforts to remedy
                effects of such force majeure.
              </p>
              <p>
                This Agreement, including all related agreements and policies
                incorporated by reference herein, constitutes the entire
                agreement between the parties related to the subject matter
                hereof and supersedes any prior or contemporaneous agreement
                between the parties relating to the Services. A valid waiver
                hereunder shall not be interpreted to be a waiver of that
                obligation in the future or any other obligation under this
                Agreement. The failure of either party to exercise in any
                respect any right provided for herein shall not be deemed a
                waiver of any further rights hereunder. In order for any waiver
                of compliance with these Terms of Service to be binding, we must
                provide you with written notice of such waiver through one of
                our authorized representatives. If any provision of this
                Agreement is prohibited by law or held to be unenforceable, that
                provision will be severed and the remaining provisions hereof
                shall not be affected such that this Agreement shall continue in
                full force and effect as if such unenforceable provision had
                never constituted a part hereof. This Agreement may be executed
                in counterparts, each of which shall be deemed an original, but
                all of which together shall constitute the same instrument. This
                Agreement may be signed electronically. These Terms of Service
                are personal to you, and are not assignable, transferable or
                sublicensable by you except with our prior written consent. We
                may assign, transfer or delegate any of our rights and
                obligations hereunder without consent. No agency, partnership,
                joint venture, or employment relationship is created as a result
                of these Terms of Service and neither party has any authority of
                any kind to bind the other in any respect. The section and
                paragraph headings in these Terms of Service are for convenience
                only and shall not affect their interpretation. Unless otherwise
                specified in these Terms of Service, all notices under these
                Terms of Service will be in writing and will be deemed to have
                been duly given when received, if personally delivered or sent
                by certified or registered mail, return receipt requested; when
                receipt is electronically confirmed, if transmitted by facsimile
                or e-mail; or the day after it is sent, if sent for next day
                delivery by recognized overnight delivery service. Electronic
                notices should be sent to{' '}
                <a href="mailto:contact@vates.fr">contact@vates.fr</a>.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Eligibility to discount operation</h2>
              <p>
                Access to specific promotional operations may be conditioned by
                prerequisites. To access these promotions, a company must comply
                with these rules throughout the period of enjoyment of the
                product. Failure to comply with these rules may result in the
                cancellation of the current offer and a switch to the nearest
                available compatible offer.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Contact</h2>
              <p>Contact. You may contact us at the following address:</p>
              <p>Vates SAS, 17 rue Aimé Beray, 38000 Grenoble, FRANCE</p>
            </Col>
          </Row>
        </Container>
        <hr style={{ width: '50%' }} />
        <Container className="terms" id="partner">
          <h1>Partner Terms and Conditions</h1>
          <div className="terms-date">Last Updated Aug. 26th, 2015</div>
          <Row>
            <Col>
              <h2>Introduction</h2>
              <p>
                The Partner agreement (also known as “Partner Terms and
                Conditions”and “Reseller Agreement”) is complementary to the End
                User terms and conditions.It applies to any company entered in
                the Partner Program.This agreement adds specific rules for a
                Partner, but Partners are under thethe End User agreement for
                any other rules.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Order requirements</h2>
              <p>
                To the extent that we make Products available for resale,You may
                order such Products for resale by following the directions
                setforth on our website. You must provide all of the requested
                information including,without limitation, the identity of the
                end user, the end user’s business and emailaddresses.All such
                information must be accurate and complete and must reflect bona
                fideorders you have received from end users.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Limited Right to Resell</h2>
              <p>
                Subject to this Reseller Agreement, we grant you a one-time,
                non-exclusive,non-transferable right to resell the Products
                specified in your ResellerOrder to the end user specified in the
                Reseller Order, solely for use bysuch end user in accordance
                with the End User Agreement. Thisright to resell does not apply
                to any other end user or Products (includingwithout limitation
                any sale to any related party, organization or affiliate,or to
                any subsequent, additional or renewal sale to the same party).
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>First sale process</h2>
              <p>
                Regarding the first sale operating by a reseller, a special rule
                apply on it. The firstsale will not grant the reseller discount
                on the plan buy. The discount will be credited ona balance that
                the reseller can use as an additionnal discount for any other
                sale. This creditnote can be use during 12 months and must be
                use for another account that the first one.This process exists
                in order to prevent abusive application to the reseller role.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Enforcement of End User Agreement</h2>
              <p>
                All use of the Products by end users is subject to the End User
                Customer Agreement,and you may not purport to impose any other
                terms pertaining to their use of the Products.You are
                responsible for ensuring that each end user enters into the End
                UserCustomer Agreement (which includes all limitations on
                Authorized Users andother quantity restrictions applicable to
                the end user’s order) in a mannerthat is legally binding upon
                the end user. This may require you to (a) notifyeach end user
                that Vates products are subject to the End User
                CustomerAgreement and that by placing an order with Reseller the
                end user agrees tothe End User Customer Agreement, (b) include
                either a copy of or link to theEnd User Customer Agreement in
                each quotation and order form you issue to theend user, and (c)
                obtain from each end user written confirmation of acceptanceof
                the End User Customer Agreement prior to the earlier to occur of
                acceptanceof the order by Reseller or delivery of the Product.
                You must provide evidenceof such acceptance by the end user to
                Vates upon request.
              </p>
              <p>
                You agree to immediately notify us of any known or suspected
                breach ofthe End User Customer Agreement or other unauthorized
                use of the Productsand to assist us in the enforcement of the
                terms of the End User Customer Agreement.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Identification as Reseller</h2>
              <p>
                Subject to this Reseller Agreement, you are permitted to
                identify yourselfas an Vates or Xen Orchestra "Reseller" solely
                in connection with your resales of Products.You may not use any
                Vates or Xen Orchestra trademark, logo or service markexcept
                those explicitly permitted by Vates.All goodwill arising from
                your use of Vates Marks inures to the benefit of Vates.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Reseller Obligations and Liability</h2>
              <p>
                <b>End User Relationships; Business Practices.</b> You agree not
                to representyourself as an agent or employee of Vates and agree
                that we willhave primary control over any end user communication
                regarding theProducts once you submit a Reseller Order. You will
                not make anyrepresentations regarding Vates, on Vates’s behalf,
                orabout any Products. You agree not to engage in any
                deceptive,misleading, illegal, or unethical practices that may
                be detrimentalto Vates or its products and agree to comply with
                all applicablefederal, state and local laws and regulations
                while operating underthis Reseller Agreement.
              </p>
              <p>
                <b>Indemnity.</b> You are fully responsible for all liabilities
                and expensesof any type whatsoever that may arise on account of
                your resale of Products.You will indemnify, hold harmless and
                (at Vates’s option) defend Vatesfrom and against any claim,
                loss, cost, liability or damage, including attorneys’fees, for
                which Vates becomes liable arising from or relating to: (a)
                anybreach or alleged by you of any term of this Reseller
                Agreement, (b) the issuanceby you of any warranty or
                representation regarding Vates or its products orservices not
                specified in the Vates Customer Agreement, or (c) any of
                yourother acts or omissions in connection with the marketing or
                resale of the Productsunder this Reseller Agreement.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Termination</h2>
              <p>
                <b>Termination.</b> We may terminate this Reseller Agreement if
                you materiallybreach any provision in this Reseller Agreement
                and fail to cure such breachwithin five (5) days of written
                notice of such breach. In addition, eitherparty may terminate
                this Reseller Agreement for any reason or no reason uponthirty
                (30) days’ written notice to the other party. Vates may also
                terminatethis Reseller Agreement immediately upon notice to you
                if (a) it ceases to offerthe current Reseller program or (b) it
                reasonably believes that continuinghereunder could result in
                business or legal liability for Vates or otherwiseharm Vates or
                its end users.
              </p>
              <p>
                <b>Effect of Termination.</b> You expressly agree that Vates
                will have no obligationor liability to you resulting from
                termination or expiration of this ResellerAgreement in
                accordance with its terms. Upon termination or expiration of
                thisReseller Agreement: (a) you must immediately cease
                identifying yourself as anVates Reseller and using Vates Marks
                in connection with your resale activitieshereunder, (b) you must
                destroy all Confidential Information in your possessionand
                certify destruction (unless we request that you return such
                materials to us).
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Changes to Agreement</h2>
              <p>
                From time to time, we may modify this Reseller Agreement. The
                version of thisReseller Agreement in place at the time you
                submit each Reseller Order is theversion that will govern such
                order. We will use reasonable efforts to notifyyou of these
                changes through communications through our website or other
                formsof communication, but we also suggest that you bookmark
                this Reseller Agreementand read it periodically.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Confidentiality</h2>
              <p>
                Except as otherwise set forth in this Reseller Agreement, each
                party agreesthat all code, inventions, know-how, business,
                technical and financialinformation it obtains (as "Receiving
                Party") from the disclosing party("Disclosing Party") constitute
                the confidential property of the DisclosingParty ("Confidential
                Information"), provided that it is identified asconfidential at
                the time of disclosure or should be reasonably known bythe
                Receiving Party to be Confidential Information due to the nature
                ofthe information disclosed and the circumstances surrounding
                the disclosure.Any Vates Technology and any performance
                information relating to theProducts shall be deemed Confidential
                Information of Vates without anymarking or further designation.
                Except as expressly authorized herein, theReceiving Party will
                hold in confidence and not use or disclose any
                ConfidentialInformation. The Receiving Party’s nondisclosure
                obligation shall not applyto information which the Receiving
                Party can document: (i) was rightfully inits possession or known
                to it prior to receipt of the Confidential Information; (ii)is
                or has become public knowledge through no fault of the Receiving
                Party; (iii)is rightfully obtained by the Receiving Party from a
                third party without breachof any confidentiality obligation;
                (iv) is independently developed by employeesof the Receiving
                Party who had no access to such information; or (v) is
                requiredto be disclosed pursuant to a regulation, law or court
                order (but only to theminimum extent required to comply with
                such regulation or order and withadvance notice to the
                Disclosing Party). The Receiving Party acknowledgesthat
                disclosure of Confidential Information would cause substantial
                harmfor which damages alone would not be a sufficient remedy,
                and thereforethat upon any such disclosure by the Receiving
                Party the Disclosing Partyshall be entitled to appropriate
                equitable relief in addition to whateverother remedies it might
                have at law.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Vates Commitments; DISCLAIMER OF WARRANTIES</h2>
              <p>
                For the avoidance of doubt, any and all commitments, indemnities
                and otherterms and conditions offered by Vates with respect to
                use of the Productsare made directly by Vates to the end user in
                accordance with the VatesCustomer Agreement and do not extend to
                you as a Reseller. We make NO WARRANTIES,EXPRESS OR IMPLIED,
                STATUTORY OR OTHERWISE, TO you as a RESELLER, INCLUDING BUT
                NOTLIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                A PARTICULAR PURPOSE,OR TITLE AND NON-INFRINGEMENT.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>LIMITATION OF LIABILITY</h2>
              <p>
                <b>Waiver of Consequential Damages.</b> TO THE FULLEST EXTENT
                ALLOWED BY LAW,NEITHER VATES NOR ITS SUPPLIERS SHALL BE LIABLE
                FOR ANY LOSS OF USE,LOST DATA, FAILURE OF SECURITY MECHANISMS,
                INTERRUPTION OF BUSINESS, ORANY INDIRECT, SPECIAL, INCIDENTAL,
                OR CONSEQUENTIAL DAMAGES OF ANY KIND(INCLUDING LOST PROFITS),
                REGARDLESS OF THE FORM OF ACTION, WHETHER INCONTRACT, TORT
                (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE,EVEN IF
                INFORMED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE.
              </p>
              <p>
                <b>Liability Cap.</b> TO THE FULLEST EXTENT ALLOWED BY LAW,
                VATES’SAND ITS THIRD-PARTY SUPPLIERS’ ENTIRE LIABILITY UNDER
                THIS RESELLERAGREEMENT SHALL NOT EXCEED THE AMOUNTS ACTUALLY
                PAID BY RESELLER TOVATES IN RESPECT OF THE RESELLER ORDER THAT
                IS THE SUBJECT OF THIS RESELLER AGREEMENT.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Contact</h2>
              <p>Contact. You may contact us at the following address:</p>
              <p>Vates SAS, 17 rue Aimé Beray, 38000 Grenoble, FRANCE</p>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default Legal
