import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const actionValidate = (modalAction, modalActionArgs, close) => {
  modalAction.apply(this, modalActionArgs)
  close()
}

const ConfirmModal = ({
  close,
  modalAction,
  modalActionArgs,
  modalConfiguration: {
    title = 'Confirmation required',
    description = 'Are you sure you want to continue?',
    closeText = 'Close',
    confirmText = 'Confirm',
  } = {},
  modalStatus,
}) => (
  <Modal isOpen={modalStatus}>
    <ModalHeader>{title}</ModalHeader>
    <ModalBody>{description}</ModalBody>
    <ModalFooter>
      <Button outline color="secondary" onClick={close}>
        {closeText}
      </Button>
      <Button
        color="success"
        onClick={() => actionValidate(modalAction, modalActionArgs, close)}
      >
        {confirmText}
      </Button>
    </ModalFooter>
  </Modal>
)

export default ConfirmModal
