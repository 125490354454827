import React from 'react'
import dayjs from 'dayjs'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import {
  getPurchaseEnd,
  isCancellable,
  underCommitment,
} from '@vates/www-xo-utils'
import { FaQuestionCircle } from 'react-icons/fa'

import { getApi } from '../../api'
import { DATE_FORMAT } from '../../utils'

const withState = provideState({
  initialState: () => ({
    cancelModal: {
      reason: undefined,
      details: undefined,
    },
    loading: false,
    modal: false,
  }),
  effects: {
    cancelPurchase(effects, purchaseNumber) {
      this.state.loading = true
      getApi(this.props.purchaseRole)
        .cancelTransfer(this.state.account.token, purchaseNumber)
        .then(() => {
          effects.notify(
            'success',
            'You have cancelled your request for purchase'
          )
          this.props.updateData()
        })
        .catch((error) => {
          effects.notify('error', error.message)
        })
        .finally(() => {
          this.state.loading = false
        })
    },
    cancel(effects, e, purchaseNumber) {
      e.preventDefault()
      this.state.loading = true
      getApi(this.props.purchaseRole)
        .cancel(
          this.state.account.token,
          purchaseNumber,
          this.state.reason,
          this.state.details
        )
        .then(() => {
          effects.notify('success', 'You have terminated a plan')
          this.state.modal = false
          this.props.updateData()
        })
        .catch((error) => {
          effects.notify('error', error.message)
        })
        .finally(() => {
          this.state.loading = false
        })
    },
    toggleModal(effects) {
      this.state.modal = !this.state.modal
    },
    handleChange(effects, e) {
      this.state.cancelModal = {
        ...this.state.cancelModal,
        [e.target.name]: e.target.value,
      }
    },
  },
  computed: {
    purchaseEnd: (_, { purchase }) => {
      const purchaseEndDate = purchase.ended || getPurchaseEnd(purchase)
      return purchaseEndDate
        ? dayjs(+purchaseEndDate).format(DATE_FORMAT)
        : null
    },
  },
})

const isCancellablePlan = (purchase, account) => {
  if (purchase.source.method !== 'stripe' || purchase.end || purchase.ended) {
    return false
  }
  return !underCommitment(purchase, account.misc.beta)
}

const EndingOn = ({ effects, state, purchase }) => {
  return (
    <div className="endingOn">
      {state.purchaseEnd && <div>{state.purchaseEnd}</div>}

      {purchase.paid &&
        purchase.source.method === 'stripe' &&
        isCancellablePlan(purchase, state.account) && (
          <div>
            <Button
              size="sm"
              outline
              color="danger"
              onClick={effects.toggleModal}
            >
              End plan and recurring bills
            </Button>
            <FaQuestionCircle id={`endPlan_${purchase.number}`} size="26" />
            <UncontrolledTooltip
              placement="top"
              target={`endPlan_${purchase.number}`}
            >
              Cancelling the plan and recurring bills will interrupt your
              subscription at the end of the current paid period.
            </UncontrolledTooltip>
          </div>
        )}

      {isCancellable(purchase) && (
        <div>
          <Button
            size="sm"
            outline
            color="danger"
            onClick={() => effects.cancelPurchase(purchase.number)}
          >
            Cancel
          </Button>
          <FaQuestionCircle id={`cancel_${purchase.number}`} size="26" />
          <UncontrolledTooltip
            placement="top"
            target={`cancel_${purchase.number}`}
          >
            You can cancel this request for purchase to access other plans or
            other payment solutions.
          </UncontrolledTooltip>
        </div>
      )}

      <Modal isOpen={state.modal} size="lg" className="modal-lg">
        <Form onSubmit={(e) => effects.cancel(e, purchase.number)}>
          <ModalHeader toggle={effects.toggleModal}>
            Subscription cancelation
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="reason">
                <strong>Why are you cancelling your subscription?</strong>
              </Label>
              <Input
                type="select"
                name="reason"
                id="reason"
                required
                onChange={effects.handleChange}
              >
                <option value="">-- select one --</option>
                <option value="issue">
                  You encountered a critical issue with XO we were unable to
                  solve.
                </option>
                <option value="expensive">
                  XO is too expensive and does not fit in your budget anymore.
                </option>
                <option value="changeHypervisor">
                  You have changed your Hypervisor therefore XO is not
                  compatible anymore.
                </option>
                <option value="changeStopActivity">
                  Your company changes/stops its activity.
                </option>
                <option value="other">Another reason.</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="details">
                <strong>
                  Can you provide additional details about the reason why you
                  are leaving?
                </strong>
              </Label>
              <Input
                type="textarea"
                name="details"
                id="details"
                required
                onChange={effects.handleChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button outline onClick={effects.toggleModal}>
              I changed my mind and want to keep my subscription
            </Button>
            <Button
              color="danger"
              style={{ float: 'right' }}
              disabled={state.loading}
              analytics-on="click"
              analytics-event="cancel subscription"
            >
              Confirm and cancel your subscription
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  )
}

export default withState(injectState(EndingOn))
