import { FaInfoCircle } from 'react-icons/fa'
import Price from 'format-price'
import React from 'react'
import { computeDue } from '@vates/www-xo-utils'
import { forEach, isEmpty } from 'lodash'
import { injectState, provideState } from 'reaclette'
import { UncontrolledTooltip } from 'reactstrap'
import {generateId} from "../../utils"

const sum = arr => {
  let sum = 0
  forEach(arr, i => {
    if (i.special) {
      sum = i.special
      return false
    }
    sum += +i.amount
  })
  return sum
}

const priceFilter = (input, currency = 'USD') =>
  Price.format('en-US', currency, input)

const withState = provideState({
  initialState: () => ({
    description: [],
    discount: 0,
    sub: 0,
    total: 0,
    id: generateId()
  }),

  effects: {
    initialize(effects) {
      let due = this.props.purchase.due
      let description = []
      let sub = 0
      if (due) {
        forEach(due.discount, discount =>
          description.push(
            `${discount.name} ${priceFilter(discount.amount, due.currency)}`
          )
        )
        if (due.balance) {
          const balance = sum(due.balance)
          description.push(
            `Credit balance ${priceFilter(balance, due.currency)}`
          )
        }
        description = description.join(', ')
        const tax = sum(due.tax)
        description += ` (Tax ${priceFilter(tax, due.currency)})`
      }
      const source = this.props.purchase && this.props.purchase.source
      if (source && source.method === 'stripe' && source.amount) {
        sub = `${priceFilter(source.amount, source.currency)}/${
          +this.props.purchase.year ? 'year' : 'month'
        }`
      }
      const total = computeDue(due)
      this.state.description = description
      this.state.discount = (this.props.purchase.due && sum(this.props.purchase.due.discount)) || 0
      this.state.sub = sub
      this.state.total = total
    }
  }
})

const DueDetail = ({ state: { description, sub, total, id }, purchase }) => (
  <>
    {sub !== 0 && sub} 
    {sub === 0 && !isEmpty(purchase.due) && (
      <>
        {priceFilter(total, purchase.due.currency)}
        &nbsp;
        {description.length > 0 && (
          <>
            <FaInfoCircle id={id} />
            <UncontrolledTooltip placement="top" target={id}>
              {description}
            </UncontrolledTooltip>
          </>
        )}
      </>
    )}
  </>
)

export default withState(injectState(DueDetail))
