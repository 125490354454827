import debounceDecorator from 'debounce-input-decorator'
import React from 'react'
import { FaCheck } from 'react-icons/fa'
import { MdWarning } from 'react-icons/md'
import { map } from 'lodash'
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { Europe, countries } from '@vates/www-xo-utils'

import * as Utils from '../../utils'
import { getApi } from '../../api'
import { sendMauticData } from '../../mautic'

const InputDebounced = debounceDecorator(350)(Input)

const withState = provideState({
  initialState: ({ billingInfo }) => ({
    ...Utils.billingInfoTemplate,
    ...billingInfo,
    loading: false,
    updatedInformation: false,
  }),
  effects: {
    async initialize(effects) {
      if (!this.state.phone && this.state.country) {
        effects.updateDialCode(this.state.country)
      }
    },
    updateDialCode(_, countryCode) {
      const countryData = countries.find(
        (country) => country.code === countryCode
      )
      this.state.dialCode = countryData
        ? countryData.dialCode
        : this.state.dialCode
    },
    handleBillingInfoChange(effects, { target }) {
      const { name, value } = target
      if (name === 'country' && !this.state.phone) {
        effects.updateDialCode(value)
      }
      this.state[name] = Utils.trimStringInput(value)
      this.state.updatedInformation = true
    },
    resetForm(_) {
      return {
        ...Utils.billingInfoTemplate,
        ...this.props.billingInfo,
        updatedInformation: false,
      }
    },
    async verifyVAT(effects) {
      try {
        const account = await getApi().verifyVatNumber(
          this.props.customerId,
          this.state.country,
          this.state.vatInComNumber
        )
        await this.props.updateData(account)
        effects.notify('success', 'EU VAT Number verified successfully')
      } catch (error) {
        effects.handleError(error)
      }
    },
    async saveAccountInfo(effects, event) {
      event.preventDefault()
      this.state.loading = true
      try {
        const billingInfo = Utils.extractBillingInfoValidData(this.state)
        const account = await getApi().updateAccountInfo(
          this.props.customerId,
          billingInfo
        )
        sendMauticData({
          email: account.email,
          firstname: account.billingInfo?.firstName,
          lastname: account.billingInfo?.lastName,
          company: account.billingInfo?.company,
          address1: account.billingInfo?.street,
          address2: account.billingInfo?.address2,
          zipcode: account.billingInfo?.zip,
          city: account.billingInfo?.city,
          donotcontact: !!account.preferences?.nomail,
        })

        await this.props.updateData(account)
        await effects.notify('success', 'Account info successfully updated')
      } catch (error) {
        effects.handleError(error)
      } finally {
        this.state.loading = false
      }
    },
  },
  computed: {
    VATNumberIsRequired: ({ country, account }) =>
      Europe.VATNumberRequired(country, account),
  },
})

const BillingInfo = ({ effects, state, billingInfo }) => (
  <div style={{ margin: '20px' }}>
    <Form onSubmit={effects.saveAccountInfo}>
      <Row>
        <Col>
          <h3> Contact information</h3>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h6 className="text-muted">
            Check your account information and update if necessary
          </h6>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md="4">
          <Input
            autoFocus
            name="firstName"
            onChange={effects.handleBillingInfoChange}
            placeholder="First name *"
            required
            type="text"
            value={state.firstName}
          />
        </Col>
        <Col md="4">
          <Input
            name="lastName"
            onChange={effects.handleBillingInfoChange}
            placeholder="Last name *"
            required
            type="text"
            value={state.lastName}
          />
        </Col>
        <Col md="4">
          <Input
            name="company"
            onChange={effects.handleBillingInfoChange}
            placeholder="Company"
            type="text"
            value={state.company}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col md="4">
          <Input
            name="position"
            onChange={effects.handleBillingInfoChange}
            placeholder="Position in the company"
            type="text"
            value={state.position}
          />
        </Col>
        <Col md="4">
          <Input
            name="webSite"
            onChange={effects.handleBillingInfoChange}
            placeholder="Website"
            type="text"
            value={state.webSite}
          />
        </Col>
      </Row>
      <br /> <br />
      <Row>
        <Col>
          <Input
            name="street"
            onChange={effects.handleBillingInfoChange}
            placeholder="address 1 *"
            required
            type="text"
            value={state.street}
          />
        </Col>
        <Col>
          <Input
            name="address2"
            onChange={effects.handleBillingInfoChange}
            placeholder="address 2"
            type="text"
            value={state.address2}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Input
            name="address3"
            onChange={effects.handleBillingInfoChange}
            placeholder="address 3"
            type="text"
            value={state.address3}
          />
        </Col>
        <Col>
          <Input
            name="address4"
            onChange={effects.handleBillingInfoChange}
            placeholder="address 4"
            type="text"
            value={state.address4}
          />
        </Col>
      </Row>
      <br /> <br />
      <Row>
        <Col>
          <Input
            name="state"
            onChange={effects.handleBillingInfoChange}
            placeholder="State"
            type="text"
            value={state.state}
          />
        </Col>
        <Col>
          <Input
            name="zip"
            onChange={effects.handleBillingInfoChange}
            placeholder="Zip code *"
            required
            type="text"
            value={state.zip}
          />
        </Col>
        <Col>
          <Input
            name="city"
            onChange={effects.handleBillingInfoChange}
            placeholder="City *"
            required
            type="text"
            value={state.city}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <InputDebounced
            name="country"
            onChange={effects.handleBillingInfoChange}
            required
            type="select"
            value={state.country}
          >
            <option value="">country *</option>
            {countries &&
              map(countries, (country) => (
                <option key={country.name} value={country.code}>
                  {country.name}
                </option>
              ))}
          </InputDebounced>
        </Col>
        <Col>
          <InputGroup>
            <Col md="3" style={{ padding: 0 }}>
              <Input
                name="dialCode"
                type="text"
                placeholder="+"
                onChange={effects.handleBillingInfoChange}
                value={state.dialCode}
                required
                style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
              />
            </Col>
            <Input
              name="phone"
              type="text"
              placeholder="Phone *"
              onChange={effects.handleBillingInfoChange}
              value={state.phone}
              required
            />
          </InputGroup>
        </Col>
        {state.VATNumberIsRequired ? (
          <Col>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>{state.country || '-'}</InputGroupText>
              </InputGroupAddon>
              <Input
                name="vatInComNumber"
                type="text"
                placeholder="EU VAT Number *"
                onChange={effects.handleBillingInfoChange}
                value={state.vatInComNumber}
                required
              />
              {state.vatInComNumber === billingInfo.vatInComNumber && (
                <>
                  {billingInfo.vatVerified === false && (
                    <>
                      <InputGroupAddon addonType="append" id="VAT">
                        <Button onClick={effects.verifyVAT}>
                          <MdWarning size="18" />
                          &nbsp;Not verified
                        </Button>
                      </InputGroupAddon>
                      <UncontrolledTooltip placement="top" target="VAT">
                        Your VAT number was not verified
                        <br />
                        due to European Commission
                        <br />
                        Services unavailability.
                        <br />
                        You can click here to retry.
                        <br />
                        An attempt will be made on any
                        <br />
                        future purchase.
                      </UncontrolledTooltip>
                    </>
                  )}
                  {billingInfo.vatVerified === true && (
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <FaCheck size="18" />
                      </InputGroupText>
                    </InputGroupAddon>
                  )}
                </>
              )}
            </InputGroup>
          </Col>
        ) : (
          <Col />
        )}
      </Row>
      <br />
      <Row>
        <Col md="12" style={{ textAlign: 'right' }}>
          <Button
            outline
            style={{ marginRight: '15px' }}
            disabled={!state.updatedInformation}
            onClick={effects.resetForm}
          >
            Reset information
          </Button>
          <Button type="submit" color="success" disabled={state.loading}>
            Update information
          </Button>
        </Col>
      </Row>
      <br />
    </Form>
  </div>
)

export default withState(injectState(BillingInfo))
