import React from 'react'
import { Elements } from 'react-stripe-elements'

import CheckoutForm from './checkout-form'

const CreditCard = props => {
  return (
    <Elements>
      <CheckoutForm card {...props} />
    </Elements>
  )
}

export default CreditCard
