import React from 'react'
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getCartWrapper } from '@vates/sales-lib'
import { injectState, provideState } from 'reaclette'
import { shortId, Order } from '@vates/www-xo-utils'

import Loader from '../../components/loader'
import { getApi } from '../../../api'
import { formatDate } from '../../../utils'
import { FaQuestionCircle } from 'react-icons/fa'

dayjs.extend(duration)
dayjs.extend(relativeTime)

export const LicenseExpiration = ({ license }) => {
  if (license.duration) {
    return (
      <Badge pill className="vates-success">
        {dayjs.duration(license.duration, 'ms').humanize(true)}
      </Badge>
    )
  }

  if (license.expires) {
    return license.expires < Date.now() ? (
      <Badge pill className="vates-danger">
        expired
      </Badge>
    ) : (
      <Badge pill className="vates-secondary">
        {formatDate(license.expires)}
      </Badge>
    )
  }

  return null
}

const withState = provideState({
  initialState: () => ({
    loading: true,
    licenses: [],
    modal: false,
  }),
  effects: {
    async loadLicenses(effects) {
      this.state.loading = true
      try {
        effects.toggleModal()
        const licenses = await getApi(this.state.role).getLicensesByOrder(
          this.state.account.token,
          this.props.order
        )
        this.state.licenses = licenses
      } catch (error) {
        await effects.handleError(error)
      } finally {
        this.state.loading = false
      }
    },
    toggleModal() {
      this.state.modal = !this.state.modal
    },
  },
  computed: {
    licenseCount: (state, props) =>
      Order.getCountLicensesCart(getCartWrapper(props.order)),
  },
})

const OrderLicenses = ({ effects, state, order }) => {
  if (state.licenseCount > 0) {
    return (
      <>
        <Button size="sm" outline onClick={effects.loadLicenses}>
          Show license{state.licenseCount > 1 ? 's' : ''}
        </Button>
        <Modal
          isOpen={state.modal}
          toggle={effects.toggleModal}
          size="lg"
          className="modal-lg"
        >
          <ModalHeader toggle={effects.toggleModal}>
            License{state.licenseCount > 1 ? 's' : ''}
          </ModalHeader>
          <ModalBody className="modal-body-license">
            {state.loading ? (
              <Loader />
            ) : (
              <Table className="table-license">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Product</th>
                    <th>
                      License bind to{' '}
                      <FaQuestionCircle size="20" id="license-tooltip" />
                      <UncontrolledTooltip
                        placement="right"
                        target="license-tooltip"
                      >
                        The value display in this column is the UUID of the VM
                        to which the XO license is attached
                      </UncontrolledTooltip>
                    </th>
                    <th>Expires</th>
                    <th>Buyer</th>
                  </tr>
                </thead>
                <tbody>
                  {state.licenses.map((license, index) => (
                    <tr key={index}>
                      <td>
                        <span className="alt-text">{shortId(license.id)}</span>
                      </td>
                      <td>
                        <Badge color="light">{license.productId}</Badge>
                      </td>
                      <td>
                        {license.boundObjectId ? (
                          license.boundObjectId
                        ) : (
                          <div>Not bound</div>
                        )}
                      </td>
                      <td>
                        <LicenseExpiration license={license} />
                      </td>
                      <td>
                        {license.buyer !== undefined && license.buyer.email}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </ModalBody>
          <ModalFooter>
            <Button outline onClick={effects.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }

  return 'No license'
}

export default withState(injectState(OrderLicenses))
