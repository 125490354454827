import { request, upload } from './fetch-api'
import { addPurchaseProperties } from '@vates/www-xo-utils'

export const uploadQuote = (xwToken, purchaseNumber, file) =>
  upload('/upload/reseller/quote', file, { xwToken, purchaseNumber })

export const uploadPurchaseOrder = (xwToken, purchaseNumber, file) =>
  upload('/upload/reseller/purchaseOrder', file, { xwToken, purchaseNumber })

export const uploadTransfer = (xwToken, purchaseNumber, file) =>
  upload('/upload/reseller/transferProof', file, { xwToken, purchaseNumber })

export const generateQuote = (xwToken, purchaseNumber, billingInfo) =>
  request('/api/reseller', 'generateQuote', {
    xwToken,
    purchaseNumber,
    billingInfo
  })

export const bindUser = (xwToken, email, purchaseNumber) =>
  request('/api/reseller', 'bindUser', { xwToken, email, purchaseNumber })

export const cancel = (xwToken, purchaseNumber, reason, details) =>
  request('/api/reseller', 'cancel', {
    xwToken,
    purchaseNumber,
    reason,
    details
  })

export const cancelTransfer = (xwToken, purchaseNumber) =>
  request('/api/reseller', 'cancelTransfer', { xwToken, purchaseNumber })

export const loadOrders = async xwToken => {
  const account = await request('/api/reseller', 'loadOrders', { xwToken })
  for (const id in account.orders.data) {
    addPurchaseProperties(account.orders.data[id])
  }
  return account
}

export const cancelOrder = orderId =>
  request('/api/reseller', 'cancelOrder', { orderId })

export const cancelPaymentIntent = orderId =>
  request('/api/reseller', 'cancelPaymentIntent', { orderId })

export const stopRenewal = (xwToken, orderId, reason, details) =>
  request('/api/reseller', 'stopRenewal', {
    xwToken,
    orderId,
    reason,
    details
  })

export const continueRenewal = (xwToken, orderId) =>
  request('/api/reseller', 'continueRenewal', {
    xwToken,
    orderId
  })

export const generateQuoteFromOrder = (xwToken, orderId) =>
  request('/api/reseller', 'generateQuoteFromOrder', {
    xwToken,
    orderId
  })

export const uploadSignedQuoteToOrder = (xwToken, orderId, file) =>
  upload('/upload/reseller/signedQuoteToOrder', file, {
    xwToken,
    orderId
  })

export const uploadPurchaseOrderToOrder = (xwToken, orderId, file) =>
  upload('/upload/reseller/purchaseOrderToOrder', file, {
    xwToken,
    orderId
  })

export const uploadTransferProofToOrder = (xwToken, orderId, file) =>
  upload('/upload/reseller/transferProofToOrder', file, { xwToken, orderId })

export const bindEndUserToOrder = (xwToken, email, orderId) =>
  request('/api/reseller', 'bindEndUserToOrder', { xwToken, email, orderId })

export const getLicensesByOrder = (xwToken, order) =>
  request('/api/reseller', 'getLicensesByOrder', { xwToken, order })
