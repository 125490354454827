import React, { Fragment } from 'react'
import { Badge } from 'reactstrap'

const ManualOrderCart = ({ cart }) => {
  const cartFiltered = cart.filter((cartItem) => cartItem.type === 'product')

  return (
    <Fragment>
      {cartFiltered.map((cartItem) => (
        <div key={cartItem.id}>
          <Badge color="light">
            x{cartItem.quantity} {cartItem.productId}
          </Badge>
        </div>
      ))}
    </Fragment>
  )
}

export default ManualOrderCart
