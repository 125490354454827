import * as ApplicationError from '@vates/xw-api-errors'
import { parse } from 'nd-json-rpc-protocol'

const handleNdJsonRpcResponse = async response => {
  const text = await response.text()
  const responseObject = parse(text)
  const { error } = responseObject.jsonrpcMessage

  if (error) {
    const code = +error.code
    const ErrorType = ApplicationError.getErrorFromCode(code)
    if (!ErrorType) {
      // Unknown error (not known between server end client)
      throw new ApplicationError.CriticalError(
        `Unexpected error: ${error.message}`
      )
    }
    throw new ErrorType(error.message)
  } else {
    return responseObject.data
  }
}

export default handleNdJsonRpcResponse
