import React from 'react'
import dayjs from 'dayjs'
import { Badge } from 'reactstrap'
import { Order } from '@vates/www-xo-utils'
import { getCartWrapper } from '@vates/sales-lib'

import { formatDate } from '../../../utils'

/**
 * @param {number} diff Duration in days between two dates
 * @returns classname
 */
const badgeClassnameEndOrder = (endDateOrder) => {
  const diffInDays = dayjs(+endDateOrder, 'x').diff(dayjs(), 'day')
  if (diffInDays <= 15) {
    return 'vates-danger'
  }
  if (diffInDays > 15 && diffInDays <= 60) {
    return 'vates-warning'
  }

  return 'vates-secondary'
}

const OrderRenew = ({ order }) => {
  if (order.status === Order.ORDER_STATUS.UP) {
    const renewalDate = Order.guessRenewalDateFromOrder(order)
    if (renewalDate) {
      return (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Badge pill className="vates-success">
            renews
          </Badge>
          <span className="alt-text"> on {formatDate(renewalDate)}</span>
        </div>
      )
    }
  }

  if (
    order.status === Order.ORDER_STATUS.CLOSED &&
    Order.getCountLicensesCart(getCartWrapper(order.cart)) > 0
  ) {
    const endDateOrder = Order.getEndDateOrder(order)
    if (endDateOrder) {
      const isEndDatePassed = dayjs(+endDateOrder, 'x').isBefore(dayjs())
      return (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Badge pill className={badgeClassnameEndOrder(endDateOrder)}>
            {isEndDatePassed ? 'ended' : 'ends'}
          </Badge>
          <span className="alt-text"> on {formatDate(endDateOrder)}</span>
        </div>
      )
    }
  }

  return null
}

export default OrderRenew
