import 'bootstrap/dist/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css'
import './style/hover.css'
import './style/general-style.css'
import App from './app'
import React from 'react'
import ReactDOM from 'react-dom'
import { initMauticScript } from './mautic'

initMauticScript()
ReactDOM.render(<App />, document.getElementById('root'))
