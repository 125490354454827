import React from 'react'
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { MdWarning } from 'react-icons/md'

import { getApi } from '../../api'

const withState = provideState({
  initialState: () => ({
    email: '',
    loading: false,
    modal: false
  }),
  effects: {
    bindUser(effects, purchaseNumber) {
      this.state.loading = true
      getApi(this.props.purchaseRole)
        .bindUser(this.state.account.token, this.state.email, purchaseNumber)
        .then(() => {
          effects.notify(
            'success',
            'Your product has been successfully attached to your end user.'
          )
          this.props.updateData()
        })
        .catch(error => {
          effects.notify('error', error.message)
        })
        .finally(() => {
          this.state.loading = false
        })
    },
    handleEmailChange(effects, e) {
      this.state.email = e.target.value
    },
    toggleModal(effects) {
      this.state.modal = !this.state.modal
    }
  }
})

const EndUser = ({ effects, state, purchase }) => {
  if (!purchase.extended) {
    if (purchase.user) {
      return purchase.user.email
    }
    if (!purchase.user && (purchase.paid || purchase.go)) {
      return (
        <>
          <Button size="sm" outline onClick={effects.toggleModal}>
            Bind to user
          </Button>

          <Modal isOpen={state.modal}>
            <Form
              onSubmit={e => {
                e.preventDefault()
                effects.bindUser(purchase.number)
              }}
            >
              <ModalHeader toggle={effects.toggleModal}>
                Unreversible binding operation
              </ModalHeader>
              <ModalBody>
                <Alert color="warning">
                  <MdWarning size="24" /> Please check carefully the spelling of
                  the email address before confirmation. You won't be able to
                  change the address without support from our team.
                </Alert>
                <Input
                  id="email"
                  name="email"
                  onChange={effects.handleEmailChange}
                  placeholder="email *"
                  required
                  style={{ marginBottom: '0.5rem' }}
                  type="email"
                  value={state.email}
                />
              </ModalBody>
              <ModalFooter>
                <Button outline onClick={effects.toggleModal}>
                  Cancel
                </Button>
                <Button
                  color="success"
                  type="submit"
                  style={{ float: 'right' }}
                  disabled={state.loading}
                >
                  Bind to user
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </>
      )
    }
  }
  return null
}

export default withState(injectState(EndUser))
