import React from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import ActionButton from '../components/action-button'
import Loader from '../components/loader'
import VatesLogo from '../../imgs/vates-logo.png'
import { getApi } from '../../api'

const withState = provideState({
  initialState: () => ({
    accountCreated: false,
    authLoading: false,
    company: '',
    email: '',
    subscribeNews: false
  }),
  effects: {
    handleChange: (_, { target }) => ({
      [target.name]: target.type === 'checkbox' ? target.checked : target.value
    }),
    async signUp(effects, event) {
      if (event) {
        event.preventDefault()
      }
      this.state.authLoading = true
      try {
        const account = await getApi().createAccount(
          this.state.email,
          this.state.company
        )
        if (this.state.subscribeNews === false) {
          await getApi().savePreferences(account.token, { nomail: true })
        }
        if (this.state.origin) {
          await getApi().saveAccountOrigin(account.token, this.state.origin)
        }
        this.state.accountCreated = true
      } catch (err) {
        await effects.handleError(err)
      }
      this.state.authLoading = false
    }
  },
  computed: {
    loading: (state, props) => state.authLoading || props.loading
  }
})

const SignupForm = ({ effects, state }) => (
  <Row style={{ marginTop: '50px' }} className="justify-content-center">
    <Col md="6" lg="4">
      <Card body>
        <CardTitle>
          <div className="text-center">
            <img src={VatesLogo} alt="Vates" width="100" />
            <div className="card-title">Create your Vates Account</div>
            <div className="card-subtitle">It's totally free!</div>
          </div>
          <br />
        </CardTitle>
        {state.accountCreated ? (
          <div className="text-center">
            <p className="information-success">
              An activation email has been sent to: {state.email}
            </p>
            <p className="information-warning">
              Don't receive the email? Wait 10 minutes or check your SPAM
              folder! Still nothing? Please{' '}
              <a href="https://vates.fr/#contact">contact us!</a>
            </p>
          </div>
        ) : state.loading ? (
          <Loader />
        ) : (
          <>
            <CardBody>
              <Form id="signup-form">
                <FormGroup>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={state.email}
                    name="email"
                    onChange={effects.handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="Company"
                    value={state.company}
                    name="company"
                    onChange={effects.handleChange}
                  />
                </FormGroup>
                <FormGroup check className="signup-newsletter-text">
                  <Label check>
                    <Input
                      type="checkbox"
                      name="subscribeNews"
                      checked={state.subscribeNews}
                      onChange={effects.handleChange}
                    />
                    I want to receive Xen Orchestra News Letters and
                    announcements by email.
                  </Label>
                </FormGroup>
                <ActionButton
                  action={effects.signUp}
                  block={true}
                  color="success"
                  disabled={state.email === ''}
                  form="signup-form"
                  text="FREE SIGN UP"
                />
              </Form>
            </CardBody>
            <div className="card-footer-link">
              <a href="/#/login" className="link-primary">
                Already have an account? Sign In
              </a>
              <div>
                <a
                  href="/#/legal"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-muted"
                >
                  By signing up, you agree to the Terms of Service
                </a>
              </div>
              <div>
                <a
                  href="/#/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-muted"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </>
        )}
      </Card>
    </Col>
  </Row>
)

export default withState(injectState(SignupForm))
