import { FaInfoCircle } from 'react-icons/fa'
import Price from 'format-price'
import React from 'react'
import { computeDue } from '@vates/www-xo-utils'
import { forEach } from 'lodash'
import { injectState, provideState } from 'reaclette'
import { UncontrolledTooltip } from 'reactstrap'

const sum = (arr) => {
  let sum = 0
  forEach(arr, (i) => {
    if (i.special) {
      sum = i.special
      return false
    }
    sum += +i.amount
  })
  return sum
}

const priceFilter = (input, currency = 'USD') =>
  Price.format('en-US', currency, input)

const withState = provideState({
  initialState: (props) => ({
    description: [],
    sub: undefined,
  }),
  computed: {
    total: (_, props) =>
      priceFilter(computeDue(props.order.due), props.order.due.currency),
    subscription: (_, props) => {
      if (props.order.paymentModel.type === 'subscription') {
        return +props.order.paymentModel.year > 0 ? '/year' : '/month'
      }
    },
    tooltip: (_, props) => {
      let tooltip = []
      const due = props.order.due
      const currency = due.currency

      forEach(due.discount, (discount) =>
        tooltip.push(
          `${discount.name} ${priceFilter(discount.amount, currency)}`
        )
      )
      if (due.balance) {
        const balance = sum(due.balance)
        tooltip.push(`Credit balance ${priceFilter(balance, currency)}`)
      }
      tooltip = tooltip.join(', ')
      const tax = sum(due.tax)
      if (tax !== 0) {
        tooltip += ` (Tax ${priceFilter(tax, currency)})`
      }

      return tooltip
    },
  },
})

const OrderDueDetail = ({ state, order }) => (
  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
    {state.tooltip.length > 0 && (
      <div style={{ paddingBottom: '3px' }}>
        <FaInfoCircle id={`due-detail${order.id}`} />
        <UncontrolledTooltip placement="top" target={`due-detail${order.id}`}>
          {state.tooltip}
        </UncontrolledTooltip>
      </div>
    )}
    &nbsp;
    {state.total}
    {state.subscription && (
      <span className="alt-text">{state.subscription}</span>
    )}
  </div>
)

export default withState(injectState(OrderDueDetail))
