import React from 'react'
import { Badge, Button, ButtonGroup, Table } from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { isEmpty } from 'lodash'
import {
  isEnded,
  isUpgradablePurchase,
  Order,
  shortId,
} from '@vates/www-xo-utils'

import DueDetail from './due-detail'
import EndUser from './end-user'
import EndingOn from './ending-on'
import PaymentStatus from './payment-status'
import { generateCrossAuthToken } from '../../api'

import './style.css'
import Orders from './orders'
import { isExtendableDuration } from '../../utils'

const withState = provideState({
  initialState: () => ({
    showEndedPurchases: false,
  }),
  effects: {
    async redirectToStore(effects, queryParams) {
      try {
        const crossAuthtoken = await generateCrossAuthToken(
          this.props.customerId
        )
        let url = `${this.state.config.storeFrontEndUrl}/#/`
        let searchParams = new URLSearchParams(
          `cat=${encodeURIComponent(crossAuthtoken)}`
        )

        if (queryParams) {
          for (const param in queryParams) {
            searchParams.append(param, queryParams[param])
          }
        }

        url += `?${searchParams.toString()}`
        window.open(url, '_blank')
      } catch (error) {
        await effects.handleError(error)
      }
    },
    handleEndedPurchases: () => (state) => ({
      showEndedPurchases: !state.showEndedPurchases,
    }),
  },
  computed: {
    activeProductsPurchaser: (_, { account }) =>
      Object.values(account.purchasing.purchases).filter(
        (purchase) => !isEnded(purchase)
      ),
    activeProductsReseller: (_, { account }) =>
      Object.values(account.reselling.purchases).filter(
        (purchase) => !isEnded(purchase)
      ),
    endedProductsPurchaser: (_, { account }) =>
      Object.values(account.purchasing.purchases).filter(
        (purchase) => purchase.paid && isEnded(purchase)
      ),
    endedProductsReseller: (_, { account }) =>
      Object.values(account.reselling.purchases).filter(
        (purchase) => purchase.paid && isEnded(purchase)
      ),
    activeOrders: ({ orders }) =>
      orders.filter((order) => !Order.isExpiredOrder(order)),
    endedOrders: ({ orders }) =>
      orders.filter((order) => Order.isExpiredOrder(order)),
    displayEndedButton: ({
      endedOrders,
      endedProductsPurchaser,
      endedProductsReseller,
    }) =>
      !isEmpty(endedOrders) ||
      !isEmpty(endedProductsPurchaser) ||
      !isEmpty(endedProductsReseller),
  },
})

const ExtendBadge = ({ purchase }) => {
  if (purchase.source.method === 'transfer') {
    if (purchase.extendedByOrder) {
      return (
        <Badge className="vates-secondary">
          Extended by <br /> # {shortId(purchase.extendedByOrder.orderId)}
        </Badge>
      )
    }
    if (purchase.extended) {
      return (
        <Badge pill className="vates-secondary">
          Extended by # {purchase.extended.number}
        </Badge>
      )
    }
    if (purchase.extends) {
      return (
        <Badge pill className="vates-secondary">
          Extends # {purchase.extends}
        </Badge>
      )
    }
  }

  return null
}

const Purchases = ({ effects, state, updateData }) => (
  <div style={{ margin: '20px' }}>
    <h3>
      Purchases management{' '}
      {state.role !== 'reseller' && (
        <Button outline onClick={() => effects.redirectToStore()}>
          Go to store
        </Button>
      )}
    </h3>
    <hr />
    <h6 className="text-muted">Here you can see your purchases</h6>
    {state.displayEndedButton && (
      <Button size="sm" outline onClick={effects.handleEndedPurchases}>
        {state.showEndedPurchases ? 'Hide' : 'Show'} ended purchases
      </Button>
    )}
    <Orders showEndedOrders={state.showEndedPurchases} />
    {(!isEmpty(state.activeProductsPurchaser) ||
      !isEmpty(state.endedProductsPurchaser)) && (
      <PurchasesTable
        effects={effects}
        endedPurchases={state.endedProductsPurchaser}
        purchaseRole="purchaser"
        purchases={state.activeProductsPurchaser}
        showEndedPurchases={state.showEndedPurchases}
        updateData={updateData}
      />
    )}
    {(!isEmpty(state.activeProductsReseller) ||
      !isEmpty(state.endedProductsReseller)) && (
      <div>
        <h6 className="text-muted">Partner resales</h6>
        <PurchasesTable
          effects={effects}
          endedPurchases={state.endedProductsReseller}
          purchaseRole="reseller"
          purchases={state.activeProductsReseller}
          showEndedPurchases={state.showEndedPurchases}
          updateData={updateData}
        />
      </div>
    )}
  </div>
)

const PurchasesTable = ({
  effects,
  endedPurchases,
  purchaseRole,
  purchases,
  showEndedPurchases,
  updateData,
}) => (
  <Table style={{ margin: '1rem 0' }}>
    <thead>
      <tr>
        <th style={{ textAlign: 'center' }}>#</th>
        <th>Plan</th>
        <th>Quantity</th>
        <th>Total amount</th>
        <th>Payment status</th>
        <th>Ending on</th>
        <th>End user account</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {purchases.map((purchase) => (
        <tr key={purchase.number}>
          <td style={{ textAlign: 'center' }}>
            {purchase.number} <br />
            <ExtendBadge purchase={purchase} />
          </td>
          <td>{purchase.id}</td>
          <td>{purchase.quantity ? purchase.quantity : 1}</td>
          <td>
            <DueDetail purchase={purchase} />
          </td>
          <td>
            <PaymentStatus
              purchase={purchase}
              updateData={updateData}
              purchaseRole={purchaseRole}
            />
          </td>
          <td>
            <EndingOn
              purchase={purchase}
              updateData={updateData}
              purchaseRole={purchaseRole}
            />
          </td>
          <td>
            <EndUser
              purchase={purchase}
              updateData={updateData}
              purchaseRole={purchaseRole}
            />
          </td>
          <td>
            <ButtonGroup vertical size="sm">
              {isUpgradablePurchase(purchase) && (
                <Button
                  outline
                  onClick={() =>
                    effects.redirectToStore({
                      purchaseNumber: purchase.number,
                      role: purchaseRole,
                    })
                  }
                >
                  Upgrades
                </Button>
              )}
              {isExtendableDuration(purchase) && (
                <Button
                  outline
                  onClick={() =>
                    effects.redirectToStore({
                      extendPurchaseNumber: purchase.number,
                      role: purchaseRole,
                    })
                  }
                >
                  Extend
                </Button>
              )}
            </ButtonGroup>
          </td>
        </tr>
      ))}
      {showEndedPurchases &&
        !isEmpty(endedPurchases) &&
        endedPurchases.map((endedPurchase) => (
          <tr key={endedPurchase.number} style={{ backgroundColor: '#eeeeee' }}>
            <td style={{ textAlign: 'center' }}>
              {endedPurchase.number} <br />
              <ExtendBadge purchase={endedPurchase} />
            </td>
            <td>{endedPurchase.id}</td>
            <td>{endedPurchase.quantity ? endedPurchase.quantity : 1}</td>
            <td>
              <DueDetail purchase={endedPurchase} />
            </td>
            <td>
              <Badge pill className="vates-danger">
                Ended
              </Badge>
            </td>
            <td>
              <EndingOn
                purchase={endedPurchase}
                updateData={updateData}
                purchaseRole={purchaseRole}
              />
            </td>
            <td>
              {!endedPurchase.extended &&
                endedPurchase.user &&
                endedPurchase.user.email}
            </td>
            <td></td>
          </tr>
        ))}
    </tbody>
  </Table>
)

export default withState(injectState(Purchases))
