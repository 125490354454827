import React from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import { getApi, generateCrossAuthToken } from '../../api'
import ActionButton from '../components/action-button'
import VatesLogo from '../../imgs/vates-logo.png'
import { sendMauticData } from '../../mautic'

const withState = provideState({
  initialState: () => ({
    password: '',
    loading: true,
  }),
  effects: {
    async initialize() {
      await this.props.decodeJwt(this.props.match.params.token)
      this.state.loading = false
    },
    async activateAccount(effects) {
      this.state.loading = true
      try {
        const account = await getApi().activateAccount(
          this.props.match.params.token,
          this.state.password
        )
        sendMauticData({
          email: account.email,
          company: account.billingInfo?.company,
          donotcontact: !!account.preferences?.nomail,
        })
        await effects.notify('success', 'Account activated')
        await effects.signIn(account.token)
        let landing = '/#/billingInfo'
        let activateEvent = 'account activated'
        if (account.origin) {
          const config = this.state.config
          const origins = {
            xcpng: 'xcpngUrl',
            xo: 'baseUrl',
          }
          const accountOrigins = {
            xcpng: 'xcp-ng',
            xo: 'xen-orchestra',
          }
          const origin = config[origins[account.origin]]
          if (origin) {
            const cat = await generateCrossAuthToken(account.token)
            landing = `${origin}/#!/?cat=${encodeURIComponent(cat)}`
          }
          if (account.origin in accountOrigins) {
            activateEvent = accountOrigins[account.origin] + ' ' + activateEvent
          }
        }
        window._paq.push(['trackEvent', 'User', activateEvent])
        window.location.replace(landing)
      } catch (error) {
        effects.handleError(error)
      } finally {
        this.state.loading = false
      }
    },
    handlePassword: (_, { target: { value } }) => ({ password: value }),
  },
})

const ActivateAccount = ({ effects, state }) => (
  <Row style={{ marginTop: '50px' }}>
    <Col sm={{ size: 4, offset: 4 }}>
      <Card body>
        <CardTitle>
          <div className="text-center">
            <img src={VatesLogo} alt="Vates" width="100" />
            <div className="card-title">Activate your account</div>
          </div>
        </CardTitle>
        <CardBody>
          <br />
          <Form id="activation-form">
            <FormGroup>
              <Input
                type="password"
                placeholder="Choose your account password"
                value={state.password}
                onChange={effects.handlePassword}
                required
              />
            </FormGroup>
            <ActionButton
              action={effects.activateAccount}
              block={true}
              color="success"
              disabled={state.password === '' || state.loading}
              form="activation-form"
              text="Activate"
            />
          </Form>
        </CardBody>
      </Card>
    </Col>
  </Row>
)

export default withState(injectState(ActivateAccount))
