import React from 'react'
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap'
import { UncontrolledTooltip } from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { BsPersonCircle } from 'react-icons/bs'
import { AiOutlineLogout } from 'react-icons/ai'

import { generateCrossAuthToken } from '../../api'
import * as Utils from '../../utils'
import XoLogo from '../../imgs/xo-logo.png'
import XcpngLogo from '../../imgs/xcpng-logo.png'
import VatesLogo from '../../imgs/vates-logo.png'

const withState = provideState({
  initialState: () => ({
    collapseNavbar: true,
  }),
  effects: {
    async goToXO(effects) {
      try {
        if (this.props.customerId) {
          const crossAuthToken = await generateCrossAuthToken(
            this.props.customerId
          )
          window.location.replace(
            `${this.props.config.baseUrl}/#/?cat=${encodeURIComponent(
              crossAuthToken
            )}`
          )
        } else {
          window.location.replace(this.props.config.baseUrl)
        }
      } catch (error) {
        effects.handleError(error)
      }
    },
    goToXcpng(effects) {
      window.location.replace(this.props.config.xcpngUrl)
    },
    toggleNavbar() {
      this.state.collapseNavbar = !this.state.collapseNavbar
    },
  },
})

const Header = ({ effects, state }) => (
  <div className="header">
    <Navbar expand="sm" dark>
      <NavbarToggler onClick={effects.toggleNavbar} className="mr-2" />
      <Collapse isOpen={!state.collapseNavbar} navbar>
        <Nav className="mr-auto" navbar>
          <NavItem style={{ cursor: 'pointer', marginRight: '3rem' }}>
            <NavLink onClick={effects.goToXO}>
              <img
                className="header-logo"
                src={XoLogo}
                alt="Xen Orchestra logo"
                height="45"
              />
              <span className="header-logo-text">XEN-ORCHESTRA</span>
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: 'pointer' }}>
            <NavLink onClick={effects.goToXcpng}>
              <img
                className="header-logo"
                src={XcpngLogo}
                alt="Xcp-ng logo"
                height="45"
              />
              <span className="header-logo-text">XCP-NG</span>
            </NavLink>
          </NavItem>
        </Nav>
        {state.logged && (
          <Nav className="ms-auto" navbar>
            <NavItem className="header-item-user">
              <UncontrolledDropdown nav inNavbar tag="div">
                <DropdownToggle nav caret tag="div">
                  <BsPersonCircle size="25" style={{ color: 'white' }} />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem header>
                    {state.account && state.account.email}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={effects.logOut}>
                    <AiOutlineLogout size="25" />
                    &nbsp; Log out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem style={{ cursor: 'pointer' }}>
              <NavLink
                href={
                  Utils.get(() => state.config.companyUrl)
                    ? `${state.config.companyUrl}`
                    : '#'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Vates logo"
                  height="40"
                  id="vatesLogoToolTip"
                  src={VatesLogo}
                />
                <UncontrolledTooltip placement="top" target="vatesLogoToolTip">
                  Vates.fr
                </UncontrolledTooltip>
              </NavLink>
            </NavItem>
          </Nav>
        )}
      </Collapse>
    </Navbar>
  </div>
)

export default withState(injectState(Header))
