import React from 'react'
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import { FaCaretRight, FaCaretDown } from 'react-icons/fa'
import { getPurchaseNextRenew, isCancelled } from '@vates/www-xo-utils'
import { injectState, provideState } from 'reaclette'
import VatesBankInfo from './vates-bank-info'

import { getApi } from '../../api'
import { formatDate } from '../../utils'

const withState = provideState({
  initialState: () => ({
    collapses: {
      first: false,
      second: true,
    },
    files: {
      purchaseOrder: null,
      signedQuote: null,
      transferProof: null,
    },
    modal: false,
    uploading: false,
  }),
  effects: {
    handleChangeFile(_, e) {
      this.state.files = {
        ...this.state.files,
        [e.target.name]: e.target.files[0],
      }
    },
    onFormSignedQuoteSubmit(effects, e, purchaseNumber) {
      e.preventDefault()
      this.state.uploading = true
      getApi(this.props.purchaseRole)
        .uploadQuote(
          this.state.account.token,
          purchaseNumber,
          this.state.files.signedQuote
        )
        .then(() => {
          effects.notify('success', 'File successfully uploaded')
          document.getElementById('signedQuote').value = ''
          this.state.files.signedQuote = null
          this.props.updateData()
        })
        .catch((error) => {
          effects.notify('error', 'Error while uploading')
        })
        .finally(() => {
          this.state.uploading = false
        })
    },
    onFormPurchaseOrderSubmit(effects, e, purchaseNumber) {
      e.preventDefault()
      this.state.uploading = true
      getApi(this.props.purchaseRole)
        .uploadPurchaseOrder(
          this.state.account.token,
          purchaseNumber,
          this.state.files.purchaseOrder
        )
        .then(() => {
          effects.notify('success', 'File successfully uploaded')
          document.getElementById('purchaseOrder').value = ''
          this.state.files.purchaseOrder = null
          this.props.updateData()
        })
        .catch((error) => {
          effects.notify('error', 'Error while uploading')
        })
        .finally(() => {
          this.state.uploading = false
        })
    },
    onFormTransferProofSubmit(effects, e, purchaseNumber) {
      e.preventDefault()
      this.state.uploading = true
      getApi(this.props.purchaseRole)
        .uploadTransfer(
          this.state.account.token,
          purchaseNumber,
          this.state.files.transferProof
        )
        .then(() => {
          effects.notify('success', 'File successfully uploaded')
          document.getElementById('transferProof').value = ''
          this.state.files.transferProof = null
          this.props.updateData()
        })
        .catch((error) => {
          effects.notify('error', 'Error while uploading')
        })
        .finally(() => {
          this.state.uploading = false
        })
    },
    generateQuote(effects, purchaseNumber) {
      this.state.uploading = true
      getApi(this.props.purchaseRole)
        .generateQuote(
          this.state.account.token,
          purchaseNumber,
          this.state.account.billingInfo
        )
        .then(() => {
          effects.notify('success', 'Quote generated')
          this.props.updateData()
        })
        .catch((error) => {
          effects.notify('error', 'Error while generating quote')
        })
        .finally(() => {
          this.state.uploading = false
        })
    },
    toggleCollapse(effects, collapseIndex) {
      this.state.collapses = {
        ...this.state.collapses,
        [collapseIndex]: !this.state.collapses[collapseIndex],
      }
    },
    toggleModal(effects) {
      this.state.modal = !this.state.modal
    },
  },
})

const RenewalDate = ({ purchase }) => {
  if (purchase.source.method === 'stripe') {
    const renewalDate = getPurchaseNextRenew(purchase)
    if (renewalDate) {
      return (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Badge pill className="vates-success">
            renews
          </Badge>
          <span className="alt-text"> on {formatDate(renewalDate)}</span>
        </div>
      )
    }
  }

  return null
}

const PaymentStatus = ({ effects, state, purchase }) => {
  if (purchase.ended) {
    return (
      <Badge pill className="vates-danger">
        Ended
      </Badge>
    )
  }

  if (purchase.paid) {
    if (purchase.source.method === 'transfer') {
      return (
        <Badge pill className="vates-success">
          Paid
        </Badge>
      )
    }
    if (purchase.source.method === 'stripe') {
      return (
        <>
          <Badge pill className="vates-success">
            Subscribed {purchase.year ? 'Yearly' : 'Monthly'}
          </Badge>
          {isCancelled(purchase) ? (
            <>
              <br />
              <Badge pill className="vates-info">
                Renewal cancelled
              </Badge>
            </>
          ) : (
            <RenewalDate purchase={purchase} />
          )}
        </>
      )
    }
  }

  if (!purchase.paid) {
    if (purchase.source.method === 'stripe') {
      return 'Waiting for bank transaction confirmation'
    }
    if (!purchase.quote) {
      return (
        <Button
          color="info"
          analytics-on="click"
          analytics-event="Generate quote"
          onClick={() => effects.generateQuote(purchase.number)}
        >
          Generate quote
        </Button>
      )
    }

    if (purchase.quote) {
      return (
        <>
          {purchase.source.transferProof ? (
            <Button outline onClick={effects.toggleModal}>
              Action
            </Button>
          ) : (
            <Button size="sm" color="warning" onClick={effects.toggleModal}>
              Action required
            </Button>
          )}
          <Modal
            isOpen={state.modal}
            toggle={effects.toggleModal}
            size="lg"
            className="modal-lg"
          >
            <ModalHeader toggle={effects.toggleModal}>
              Action required
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col sm="8">
                    <Button
                      color="info"
                      style={{ marginBottom: '1rem' }}
                      href={`${window.location.protocol}//${window.location.host}/downloads/${purchase.quote}?terms=true`}
                    >
                      Download quote
                    </Button>
                    <Card>
                      <CardHeader
                        onClick={() => effects.toggleCollapse('first')}
                        style={{ cursor: 'pointer' }}
                        tag="h6"
                      >
                        {state.collapses.first ? (
                          <FaCaretDown size="22" />
                        ) : (
                          <FaCaretRight size="22" />
                        )}
                        PROFORMA / Purchase Order
                      </CardHeader>
                      <Collapse isOpen={state.collapses.first}>
                        <CardBody>
                          {!purchase.source.signedQuote && (
                            <Alert color="info">
                              If you need a PROFORMA invoice, you can download
                              the provided quote, and upload it back, signed.
                            </Alert>
                          )}
                          {purchase.source.signedQuote && (
                            <>
                              {!purchase.source.proforma && (
                                <Alert color="info">
                                  Your PROFORMA invoice will be available soon,
                                  after our team has checked the quote you
                                  uploaded. In case of mistake, you can still
                                  upload a new one.
                                </Alert>
                              )}
                              <Button
                                outline
                                color="secondary"
                                style={{ marginBottom: '1rem' }}
                                href={`${window.location.protocol}//${window.location.host}/downloads/${purchase.source.signedQuote}?terms=true`}
                              >
                                Check your previously uploaded signed quote.
                              </Button>
                            </>
                          )}

                          {!purchase.source.proforma && (
                            <Form
                              onSubmit={(e) =>
                                effects.onFormSignedQuoteSubmit(
                                  e,
                                  purchase.number
                                )
                              }
                            >
                              <Input
                                type="file"
                                id="signedQuote"
                                name="signedQuote"
                                style={{ marginBottom: '0.5rem' }}
                                required
                                onChange={effects.handleChangeFile}
                              />
                              <Button
                                block
                                color="primary"
                                disabled={state.uploading}
                              >
                                Upload signed quote
                              </Button>
                            </Form>
                          )}

                          {purchase.source.proforma && (
                            <>
                              <br />
                              <Button
                                color="info"
                                style={{ marginBottom: '1rem' }}
                                href={`${window.location.protocol}//${window.location.host}/downloads/${purchase.quote}?proforma=true&terms=true`}
                                analytics-on="click"
                                analytics-event="Download proforma"
                              >
                                Download a PROFORMA Invoice
                              </Button>
                              {purchase.source.purchaseOrder && (
                                <>
                                  {!purchase.go && (
                                    <Alert color="info">
                                      {!purchase.extends &&
                                        `Your XOA ${purchase.id} will be
                                        available very soon, after our team
                                        has checked your purchase order.`}
                                      {purchase.extends &&
                                        `Your XOA ${purchase.id} will be
                                        extended very soon, after our team has
                                        checked your purchase order.`}
                                      &nbsp;In case of mistake, you can still
                                      upload a new one. <br />
                                      <Button
                                        outline
                                        color="secondary"
                                        href={`${window.location.protocol}//${window.location.host}/downloads/${purchase.source.purchaseOrder}?terms=true`}
                                      >
                                        Check previously uploaded purchase
                                        order.
                                      </Button>
                                    </Alert>
                                  )}
                                </>
                              )}

                              {!purchase.go && (
                                <Form
                                  onSubmit={(e) =>
                                    effects.onFormPurchaseOrderSubmit(
                                      e,
                                      purchase.number
                                    )
                                  }
                                >
                                  <Input
                                    type="file"
                                    id="purchaseOrder"
                                    name="purchaseOrder"
                                    style={{ marginBottom: '0.5rem' }}
                                    required
                                    onChange={effects.handleChangeFile}
                                  />
                                  <Button
                                    block
                                    color="primary"
                                    analytics-on="click"
                                    analytics-event="Upload purchase order"
                                    disabled={state.uploading}
                                  >
                                    Upload a purchase order
                                  </Button>
                                </Form>
                              )}

                              {purchase.go && (
                                <Alert color="info">
                                  {!purchase.extends &&
                                    `Your XOA ${purchase.id} is now available.
                                    Remember your purchase will be completely
                                    finalized after payment and uploading of a
                                    transfer proof.`}
                                  {purchase.extends &&
                                    `Your XOA ${purchase.id} is now extended.
                                    Remember your purchase will be completely
                                    finalized after payment and uploading of a
                                    transfer proof.`}
                                </Alert>
                              )}
                            </>
                          )}
                        </CardBody>
                      </Collapse>
                    </Card>
                    <br />
                    <Card>
                      <CardHeader
                        onClick={() => effects.toggleCollapse('second')}
                        style={{ cursor: 'pointer' }}
                        tag="h6"
                      >
                        {state.collapses.second ? (
                          <FaCaretDown size="22" />
                        ) : (
                          <FaCaretRight size="22" />
                        )}
                        Transfer proof
                      </CardHeader>
                      <Collapse isOpen={state.collapses.second}>
                        <CardBody>
                          {purchase.source.transferProof && (
                            <Alert color="info">
                              Your {purchase.id} purchase will be finalized very
                              soon, after our team has checked your transfer
                              proof. In case of mistake, you can still upload a
                              new one. <br />
                              <Button
                                outline
                                color="secondary"
                                href={`${window.location.protocol}//${window.location.host}/downloads/${purchase.source.transferProof}?terms=true`}
                              >
                                Check previously uploaded transfer proof
                              </Button>
                            </Alert>
                          )}
                          <Form
                            onSubmit={(e) =>
                              effects.onFormTransferProofSubmit(
                                e,
                                purchase.number
                              )
                            }
                          >
                            <Input
                              type="file"
                              id="transferProof"
                              name="transferProof"
                              style={{ marginBottom: '0.5rem' }}
                              required
                              onChange={effects.handleChangeFile}
                            />
                            <Button
                              block
                              color="primary"
                              analytics-on="click"
                              analytics-event="Upload proof"
                              disabled={state.uploading}
                            >
                              Upload a transfer proof
                            </Button>
                          </Form>
                        </CardBody>
                      </Collapse>
                    </Card>
                  </Col>
                  <Col sm="4">
                    <VatesBankInfo />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button outline onClick={effects.toggleModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )
    }
  }

  return null
}

export default withState(injectState(PaymentStatus))
