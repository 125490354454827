import React from 'react'
import { Card, CardBody, CardTitle, Col, Button, Row } from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import VatesLogo from '../../imgs/vates-logo.png'
import { getApi } from '../../api'

const withState = provideState({
  initialState: () => ({
    loading: false
  }),
  effects: {
    async unsubscribe(effects) {
      this.state.loading = true
      try {
        await getApi().unsubscribe(this.props.match.params.token)
        await effects.notify('success', 'Email unsubscribed')
        this.state.loading = false
        this.props.history.push('/')
      } catch (error) {
        this.state.loading = false
        effects.handleError(error)
      }
    }
  }
})

const Unsubscribe = ({ effects, state }) => (
  <Row style={{ marginTop: '50px' }}>
    <Col sm={{ size: 5, offset: 3 }}>
      <Card body>
        <CardTitle>
          <div className="text-center">
            <img src={VatesLogo} alt="Vates" width="100" />
            <div className="card-title">Unsubscribe</div>
          </div>
        </CardTitle>
        <CardBody>
          <p className="text-center text-muted">
            Are you sure you want to unsubscribe this account from
            xen-orchestra.com news letters and informations ?
          </p>
          <Button
            color="success"
            block
            disabled={state.loading}
            onClick={effects.unsubscribe}
          >
            Unsubscribe
          </Button>
        </CardBody>
      </Card>
    </Col>
  </Row>
)

export default withState(injectState(Unsubscribe))
