import React from 'react'
import { Alert } from 'reactstrap'

const ErrorPage = () => (
  <Alert color="warning" className="text-center">
    The application was unable to start correctly
  </Alert>
)

export default ErrorPage
