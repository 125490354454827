import React, { Fragment } from 'react'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import dayjs from 'dayjs'
import { Badge, Button, ButtonGroup, Table } from 'reactstrap'
import { getCartWrapper } from '@vates/sales-lib'
import { injectState, provideState } from 'reaclette'
import { isEmpty } from 'lodash'
import { shortId, Order } from '@vates/www-xo-utils'

import { getApi } from '../../../api'
import Loader from '../../components/loader'
import ManualOrderAmount from '../manual-orders/manual-order-amount'
import ManualOrderCart from '../manual-orders/manual-order-cart'
import OrderAction from './order-action'
import OrderDueDetail from './order-due-detail'
import OrderEndUser from './order-end-user'
import OrderLicenses from './order-licenses'
import OrderRenew from './order-renew'
import OrderStatus from './order-status'
import StopRenewal from './stop-renewal'
import ComputeTotalOrder from './compute-total-order'
import { DATE_FORMAT } from '../../../utils'

dayjs.extend(advancedFormat)

const withState = provideState({
  effects: {
    async cancelOrder(effects, orderId) {
      try {
        await getApi(this.state.role).cancelOrder(orderId)
        effects.notify('success', 'Order has been successfully cancelled.')
        await effects.loadOrders()
        await effects.updateData()
      } catch (error) {
        await effects.handleError(error)
      }
    },
    async continueRenewal(effects, orderId) {
      try {
        await getApi(this.state.role).continueRenewal(
          this.state.account.token,
          orderId
        )
        effects.notify('success', 'Payment has been successfully continued.')
        await effects.loadOrders()
      } catch (error) {
        await effects.handleError(error)
      }
    },
    async cancelPaymentIntent(effects, orderId) {
      try {
        await getApi(this.state.role).cancelPaymentIntent(orderId)
        effects.notify('success', 'Payment has been successfully cancelled.')
        await effects.loadOrders()
      } catch (error) {
        await effects.handleError(error)
      }
    },
  },
  computed: {
    hasResellerSales: ({ orders }) =>
      orders.some((order) => order.role === 'reseller'),
  },
})

const OrderRow = ({ effects, order, hasResellerSales }) => (
  <tr style={{ height: '80px' }}>
    {hasResellerSales && (
      <td>
        {order.role === 'reseller' && (
          <Badge className={'vates-bgrey'}>R</Badge>
        )}
      </td>
    )}
    <td>
      <div className="nowrap">
        {dayjs(order.placingDate).format('MMM DD, YYYY')}
      </div>
      <div className="alt-text">{shortId(order.id)}</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '4px',
        }}
      >
        {order.extendsPurchase && (
          <Badge className="vates-secondary">
            Extends # {order.extendsPurchase}
          </Badge>
        )}
        {order.upgradesOrder && (
          <Badge className="vates-secondary">
            Upgrades # {shortId(order.upgradesOrder)}
          </Badge>
        )}
        {order.upgradedByOrder && (
          <Badge className="vates-secondary">
            Upgraded by # {shortId(order.upgradedByOrder)}
          </Badge>
        )}
        {order.extendsOrder && (
          <Badge className="vates-secondary">
            Extends # {shortId(order.extendsOrder)}
          </Badge>
        )}
        {order.extendedByOrder && (
          <Badge className="vates-secondary">
            Extended by # {shortId(order.extendedByOrder.orderId)}
          </Badge>
        )}
      </div>
    </td>
    <td>
      {getCartWrapper(order).licenseHolders.map((product, index) => (
        <div key={index}>
          <Badge color="light" key={index}>
            x{product.quantity} {product.productId}
          </Badge>
        </div>
      ))}
    </td>
    <td>
      {isEmpty(order.due) ? (
        <ComputeTotalOrder order={order} />
      ) : (
        <OrderDueDetail order={order} />
      )}
    </td>
    <td>
      <OrderStatus order={order} />
      <OrderRenew order={order} />
      <div className="nowrap" style={{ marginTop: '5px' }}>
        <OrderAction order={order} />
      </div>
    </td>
    <td>
      <OrderLicenses order={order} />
    </td>
    <td>
      <OrderEndUser order={order} />
    </td>
    <td>
      <ButtonGroup vertical size="sm">
        {Order.isUpgradableOrder(order) && (
          <Button
            size="sm"
            outline
            onClick={() =>
              effects.redirectToStore({
                upgradeOrder: order.id,
              })
            }
          >
            Upgrade
          </Button>
        )}
        {Order.isCancellableOrder(order) && (
          <Button
            size="sm"
            outline
            color="danger"
            onClick={() =>
              effects.confirm(effects.cancelOrder, [order.id], {
                confirmText: 'I confirm my cancellation',
                closeText: "I don't want to cancel",
              })
            }
          >
            Cancel Order
          </Button>
        )}
        {Order.isCancellablePaymentIntent(order) && (
          <Button
            size="sm"
            outline
            color="danger"
            onClick={() =>
              effects.confirm(effects.cancelPaymentIntent, [order.id])
            }
          >
            Cancel Payment Intent
          </Button>
        )}
        {Order.isStoppableRenewal(order) && <StopRenewal orderId={order.id} />}
        {Order.canContinueRenewal(order) && (
          <Button
            size="sm"
            outline
            onClick={() =>
              effects.confirm(effects.continueRenewal, [order.id], {
                description: 'Are you sure you want to continue your renewal?',
              })
            }
          >
            Continue renewal
          </Button>
        )}
        {Order.isExtendableOrder(order) && (
          <Button
            size="sm"
            outline
            onClick={() =>
              effects.redirectToStore({
                extendOrderId: order.id,
              })
            }
          >
            Extend
          </Button>
        )}
      </ButtonGroup>
    </td>
  </tr>
)

const ManualOrderRow = ({ order, hasResellerSales }) => (
  <tr style={{ height: '80px' }}>
    {hasResellerSales && (
      <td>
        {order.role === 'reseller' && (
          <Badge className={'vates-bgrey'}>R</Badge>
        )}
      </td>
    )}
    <td>
      <div className="nowrap">
        {dayjs(order.placingDate).format('MMM DD, YYYY')}
      </div>
      <div className="alt-text">{shortId(order.id)}</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: '4px',
        }}
      >
        {order.extendsPurchase && (
          <Badge className="vates-secondary">
            Extends # {order.extendsPurchase}
          </Badge>
        )}
        {order.upgradesOrder && (
          <Badge className="vates-secondary">
            Upgrades # {shortId(order.upgradesOrder)}
          </Badge>
        )}
        {order.upgradedByOrder && (
          <Badge className="vates-secondary">
            Upgraded by # {shortId(order.upgradedByOrder)}
          </Badge>
        )}
        {order.extendsOrder && (
          <Badge className="vates-secondary">
            Extends # {shortId(order.extendsOrder)}
          </Badge>
        )}
        {order.extendedByOrder && (
          <Badge className="vates-secondary">
            Extended by # {shortId(order.extendedByOrder.orderId)}
          </Badge>
        )}
      </div>
    </td>
    <td>
      <ManualOrderCart cart={order.cart} />
    </td>
    <td>
      <ManualOrderAmount order={order} />
    </td>
    <td>
      <OrderStatus order={order} />
      <OrderRenew order={order} />
      <div className="nowrap" style={{ marginTop: '5px' }}>
        <OrderAction order={order} />
      </div>
    </td>
    <td>
      <OrderLicenses order={order} />
    </td>
    <td>
      <OrderEndUser order={order} />
    </td>
    <td></td>
  </tr>
)

const Orders = ({ effects, state, showEndedOrders }) => {
  if (state.ordersLoading) {
    return <Loader />
  }

  if (state.orders.length === 0) {
    return null
  }

  return (
    <div style={{ marginTop: '10px' }}>
      {state.hasResellerSales && (
        <div>
          <Badge className="vates-bgrey">R</Badge>
          <span className="legend">Reseller sales</span>
        </div>
      )}
      <Table style={{ marginBottom: '3rem' }}>
        <thead>
          <tr className="nowrap">
            {state.hasResellerSales && <th></th>}
            <th>Order #</th>
            <th>Cart</th>
            <th>Total amount</th>
            <th>Status</th>
            <th>Licenses</th>
            <th>End user account</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {state.activeOrders.map((order) => (
            <Fragment key={order.id}>
              {order.isManual ? (
                <ManualOrderRow
                  order={order}
                  hasResellerSales={state.hasResellerSales}
                />
              ) : (
                <OrderRow
                  effects={effects}
                  order={order}
                  hasResellerSales={state.hasResellerSales}
                />
              )}
            </Fragment>
          ))}
          {showEndedOrders &&
            state.endedOrders.map((order, index) => (
              <tr
                key={index}
                style={{ height: '80px', backgroundColor: '#eeeeee' }}
              >
                {state.hasResellerSales && (
                  <td>
                    {order.role === 'reseller' && (
                      <Badge className={'vates-bgrey'}>R</Badge>
                    )}
                  </td>
                )}
                <td>
                  <div className="nowrap">
                    {dayjs(order.placingDate).format(DATE_FORMAT)}
                  </div>
                  <div className="alt-text">{shortId(order.id)}</div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      rowGap: '4px',
                    }}
                  >
                    {order.extendsPurchase && (
                      <Badge className="vates-secondary">
                        Extends # {order.extendsPurchase}
                      </Badge>
                    )}
                    {order.upgradesOrder && (
                      <Badge className="vates-secondary">
                        Upgrades # {shortId(order.upgradesOrder)}
                      </Badge>
                    )}
                    {order.upgradedByOrder && (
                      <Badge className="vates-secondary">
                        Upgraded by # {shortId(order.upgradedByOrder)}
                      </Badge>
                    )}
                    {order.extendsOrder && (
                      <Badge className="vates-secondary">
                        Extends # {shortId(order.extendsOrder)}
                      </Badge>
                    )}
                    {order.extendedByOrder && (
                      <Badge className="vates-secondary">
                        Extended by # {shortId(order.extendedByOrder.orderId)}
                      </Badge>
                    )}
                  </div>
                </td>
                <td>
                  {getCartWrapper(order).licenseHolders.map(
                    (product, index) => (
                      <div key={index}>
                        <Badge color="light" key={index}>
                          x{product.quantity} {product.productId}
                        </Badge>
                      </div>
                    )
                  )}
                </td>
                <td>
                  <OrderDueDetail order={order} />
                </td>
                <td>
                  <OrderStatus order={order} />
                  <OrderRenew order={order} />
                </td>
                <td>
                  <OrderLicenses order={order} />
                </td>
                <td>
                  <OrderEndUser order={order} />
                </td>
                <td></td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  )
}

export default withState(injectState(Orders))
