/* eslint-disable */
export const initMauticScript = () => {
  (function (w, d, t, u, n, a, m) {
    w['MauticTrackingObject'] = n;
    w[n] = w[n] || function () {
      (w[n].q = w[n].q || []).push(arguments)
    }, a = d.createElement(t),
      m = d.getElementsByTagName(t)[0];
    a.async = 1;
    a.src = u;
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', process.env.REACT_APP_MAUTIC_URL, 'mt')
}

export const sendMauticData = (data) => {
  // Check if global variable 'mt' is defined
  if (typeof mt === 'function') {
    mt('send', 'pageview', data)
  }
}
