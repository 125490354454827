import React from 'react'
import { Col, Row } from 'reactstrap'
import AccountDelete from './account-delete'
import ChangeEmail from './change-email'
import ChangePassword from './change-password'
import Preferences from './preferences'

const AccountManagement = (props) => (
  <div style={{ margin: '20px' }}>
    <Row>
      <Col>
        <h3>Account management</h3>
      </Col>
    </Row>
    <hr />
    <ChangePassword customerId={props.customerId} />
    <br />
    <ChangeEmail customerId={props.customerId} updateData={props.updateData} />
    <br />
    <Preferences
      preferences={props.preferences}
      customerId={props.customerId}
      updateData={props.updateData}
    />
    <br />
    <AccountDelete customerId={props.customerId} logOut={props.logOut} />
  </div>
)

export default AccountManagement
