import * as ApplicationError from '@vates/xw-api-errors'
import HttpClient from './http-client'
import HandleJsonRpcResponse from './handle-json-rpc-response'
import HandleNdJsonRpcResponse from './handler-nd-json-rpc-response'
import HandleUploadResponse from './handle-upload-response'
import { format } from 'json-rpc-protocol'

export const request = async (route, method, params) => {
  const body = format.request('store', method, params)
  const response = await HttpClient(route, body)
  const contentType = response.headers.get('Content-Type')
  if (contentType && contentType.includes('application/x-nd-json')) {
    return await HandleNdJsonRpcResponse(response)
  } else if (contentType && contentType.includes('application/json')) {
    return await HandleJsonRpcResponse(response)
  } else {
    throw new ApplicationError.CriticalError(
      'Unexpected content type in response headers'
    )
  }
}

export const upload = async (route, fileToUpload, params) => {
  const body = new FormData()
  body.append('file', fileToUpload)
  for (const [key, value] of Object.entries(params)) {
    body.append(key, typeof value === 'object' ? JSON.stringify(value) : value)
  }
  const response = await HttpClient(route, body)
  const contentType = response.headers.get('Content-Type')
  if (contentType && contentType.includes('application/json')) {
    return await HandleUploadResponse(response)
  } else {
    throw new ApplicationError.CriticalError(
      'Unexpected content type in response headers'
    )
  }
}
